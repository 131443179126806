import { Typography, Link as LinkComponent } from '@mui/joy';
import styles from './login.module.css';
import { GoogleButton } from './google-btn/GoogleButton';

export function Login() {
  const handleGoogleLogin = () => {
    window.open(`/sso/google`, '_self');
  };

  return (
    <>
      <div className={styles.container}>
        <div className={styles.formContainer}>
          <Typography level="h1" sx={{ color: '#fff', marginBottom: '20px' }}>
            Welcome to Arka
          </Typography>
          <GoogleButton handleClick={handleGoogleLogin} />
        </div>
      </div>
    </>
  );
}
