import { Outreach, OutreachThread } from 'api-types';

export const hasPhoneConversationOnOutreachThread = (outreachThread: OutreachThread) =>
  !!outreachThread.outreachPhoneConversations?.length;

export const hasEmailReplyOnOutreachThread = (outreachThread: OutreachThread) =>
  !!outreachThread.outreachMessages?.find(
    message => message.senderId === outreachThread.candidateId
  );

export const isOutreachThreadFinished = (
  outreachThread: OutreachThread,
  outreach: Outreach | undefined
) => {
  const phoneConversations = outreachThread.outreachPhoneConversations || [];
  const outreachMessages = outreachThread.outreachMessages || [];
  return (
    hasPhoneConversationOnOutreachThread(outreachThread) ||
    hasEmailReplyOnOutreachThread(outreachThread) ||
    [...phoneConversations, ...outreachMessages].length >= (outreach?.stages?.length || 0)
  );
};
