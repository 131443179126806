import { CircularProgress } from '@mui/joy';
import styles from './loader.module.css';

export function SpinnerLoader() {
  return (
    <div className={styles.loader}>
      <CircularProgress />
    </div>
  );
}
