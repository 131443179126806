import { Card, CardContent, Typography } from '@mui/joy';
import styles from './component.module.css';

interface CardCheckboxProps {
  value: boolean | undefined;
  card: { heading: string; content?: string };
  onChange: (value: boolean) => void;
}

export function CardCheckbox({ value, card, onChange }: CardCheckboxProps) {
  const handleOnChange = (value: boolean) => {
    onChange(value);
  };
  return (
    <>
      <div className={styles.cardContainer}>
        <Card
          variant={value ? 'solid' : 'outlined'}
          size="sm"
          invertedColors={value}
          className={styles.card}
          sx={{
            width: 320,
            ...(!value && { backgroundColor: 'rgb(255, 255, 255)' }),
            '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
          }}
          onClick={() => handleOnChange(!value)}
        >
          <CardContent>
            <Typography level="title-sm">{card.heading}</Typography>
            {card.content && (
              <Typography level="body-sm" color={'neutral'}>
                {card.content}
              </Typography>
            )}
          </CardContent>
        </Card>
      </div>
    </>
  );
}
