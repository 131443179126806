import React, { useEffect } from 'react';
import { CreateProject } from '../project/create-project/CreateProject';
import { AppNavigation } from './app-nav/AppNavigation';
import { Route, Routes } from 'react-router-dom';
import styles from './App.module.css';
import { ProjectHome } from '../project/project-home/ProjectHome';
import { DRAWER_WIDTH, PROJECT_PATH } from '../../constants';
import { useAppStore } from '../../store/appStore';
import { Snackbar } from '../../components/Snackbar/SnackbarContext';

function App() {
  const getBootstrapInfo = useAppStore(state => state.getBootstrapInfo);
  const bootstrapInfo = useAppStore(state => state.bootstrapInfo);

  useEffect(() => {
    if (!bootstrapInfo) {
      getBootstrapInfo();
    }
  }, []);

  return (
    <div className={styles.app}>
      <Snackbar>
        <AppNavigation />
        <div className={styles.appContainer} style={{ width: `calc(100% - ${DRAWER_WIDTH}px)` }}>
          <Routes>
            <Route path="/" element={<CreateProject />} />
            <Route path={`${PROJECT_PATH}/*`} element={<ProjectHome />} />
          </Routes>
        </div>
      </Snackbar>
    </div>
  );
}

export default App;
