import { Snackbar as MuiSnackbar } from '@mui/joy';
import { PropsWithChildren, createContext, useContext, useMemo, useState } from 'react';

interface SnackbarContext {
  showSuccessSnackbar: (message: string) => void;
  showErrorSnackbar: (message: string) => void;
}

const SnackBarContext = createContext<SnackbarContext | undefined>(undefined);

export function Snackbar(props: PropsWithChildren<{}>) {
  const [showSuccessSnackbar, setShowSuccessSnackbar] = useState(false);
  const [showErrorSnackbar, setShowErrorSnackbar] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const value = useMemo(
    () => ({
      showSuccessSnackbar: (message: string) => {
        setShowSuccessSnackbar(true);
        setSnackbarMessage(message);
      },
      showErrorSnackbar: (message: string) => {
        setShowErrorSnackbar(true);
        setSnackbarMessage(message);
      },
    }),
    [setShowErrorSnackbar, setShowSuccessSnackbar, setSnackbarMessage]
  );

  return (
    <SnackBarContext.Provider value={value}>
      {props.children}
      {showSuccessSnackbar && (
        <MuiSnackbar
          open={showSuccessSnackbar}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={() => {
            setShowSuccessSnackbar(false);
          }}
          color="success"
          variant="soft"
        >
          {snackbarMessage}
        </MuiSnackbar>
      )}
      {showErrorSnackbar && (
        <MuiSnackbar
          open={showErrorSnackbar}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
          onClose={() => {
            setShowErrorSnackbar(false);
          }}
          color={'danger'}
          variant="soft"
        >
          {snackbarMessage}
        </MuiSnackbar>
      )}
    </SnackBarContext.Provider>
  );
}

export function useSnackbar() {
  const context = useContext(SnackBarContext);
  if (!context) {
    throw new Error('useSnackbar must be used within a SnackbarProvider');
  }
  return context;
}
