import Typography from '@mui/joy/Typography';
import styles from './create-project.module.css';
import { useAppStore } from '../../../store/appStore';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProjectForm } from '../project-form/ProjectForm';
import { Project } from 'api-types';

export function CreateProject() {
  const setActiveProjectId = useAppStore(state => state.setActiveProjectId);
  const getBootstrapInfo = useAppStore(state => state.getBootstrapInfo);

  const navigate = useNavigate();

  const onSubmitSuccess = async (project: Project | undefined) => {
    // refetch bootstrap info
    await getBootstrapInfo();
    navigate(`/project/${project?.id}`);
  };

  useEffect(() => {
    setActiveProjectId(undefined);
  }, []);

  return (
    <div className={styles.searchContainer}>
      <div className={styles.heading}>
        <Typography level="h3">Create a new project</Typography>
      </div>
      <ProjectForm onSubmitSuccess={onSubmitSuccess} />
    </div>
  );
}
