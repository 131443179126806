import { Drawer } from '@mui/material';
import styles from './admin.module.css';
import { Link, NavLink } from 'react-router-dom';
import { Typography, List, ListItem, ListItemButton, ListItemContent, ListDivider } from '@mui/joy';
import logo from '../../assets/logo_v2.png';
import { DRAWER_WIDTH } from '../../constants';
import { NAV_ITEMS } from './constants';

export function AdminNavigation() {
  return (
    <Drawer
      sx={{
        width: DRAWER_WIDTH,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: DRAWER_WIDTH,
          boxSizing: 'border-box',
        },
      }}
      PaperProps={{
        sx: {
          backgroundColor: '#272525',
          color: '#272525',
        },
      }}
      variant="permanent"
      anchor="left"
      className={styles.appNav}
    >
      <List>
        <ListItem className={styles.listItem}>
          <ListItemButton
            className={styles.listButton}
            to={'/'}
            component={Link}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent !important',
              },
            }}
          >
            <ListItemContent>
              <Typography level="h3" sx={{ color: '#fff' }} className={styles.heading}>
                <img src={logo} alt="logo" className={styles.logo} />
                Arka
              </Typography>
            </ListItemContent>
          </ListItemButton>
        </ListItem>
        <ListDivider className={styles.divider} />
        {NAV_ITEMS.map(({ title, path }) => (
          <ListItem className={styles.listItem}>
            <ListItemButton
              to={path}
              component={NavLink}
              className={styles.listButton}
              sx={{
                '&:hover': {
                  backgroundColor: '#4d4d4dfc !important',
                },
              }}
              style={({ isActive }: { isActive: boolean }) => ({
                color: isActive ? '#fff' : '#545e6f',
                'background-color': isActive ? '#4d4d4dfc' : 'inherit',
              })}
            >
              <ListItemContent>
                <Typography level="title-sm" sx={{ color: '#fff' }}>
                  {title}
                </Typography>
              </ListItemContent>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Drawer>
  );
}
