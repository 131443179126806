import { Route, Routes } from 'react-router-dom';
import { OutreachConfigEditor } from '../outreach-config/OutreachConfigEditor';
import { OutreachHome } from '../outreach-home/OutreachHome';
import { OUTREACH_EDITOR_PATH } from '../../../constants';
import styles from '../outreach-home/outreach.module.css';
import { useAppStore } from '../../../store/appStore';
import { useEffect, useState } from 'react';
import { SpinnerLoader } from '../../../components/Loader/SpinnerLoader';

export function OutreachRoutes() {
  const [loading, setLoading] = useState(true);
  const fetchOutreach = useAppStore(state => state.fetchOutreach);

  const asyncFetchOutreach = async () => {
    setLoading(true);
    try {
      await fetchOutreach();
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    asyncFetchOutreach();
  }, []);

  return (
    <div className={styles.outreachContainer}>
      {loading ? (
        <SpinnerLoader />
      ) : (
        <Routes>
          <Route path="/" element={<OutreachHome />} />
          <Route path={`${OUTREACH_EDITOR_PATH}`} element={<OutreachConfigEditor />} />
        </Routes>
      )}
    </div>
  );
}
