import { GetState, SetState } from 'zustand';
import { AppStore } from '../../../store/types';
import { CandidateStage, FileContent, PipelineStageEnum } from 'api-types';
import {
  batchAddCandidatesToPipeline,
  batchUpdateCandidateStages,
  batchUploadCandidates,
  getCandidatePipeline,
  searchCandidatesByProject,
} from '../../../api/api';
import { CandidateSlice, SearchCandidateParams } from './types';

const initialState: Partial<CandidateSlice> = {
  searchCandidateResults: undefined,
  candidatePipeline: undefined,
};

export const SEARCH_CANDIDATES_PAGE_SIZE = 10;

export const createCandidateSlice = (
  set: SetState<AppStore>,
  get: GetState<AppStore>
): CandidateSlice => ({
  ...initialState,
  resetCandidateSlice() {
    set({
      ...initialState,
    });
  },
  searchCandidates: async ({ offset, refresh }: SearchCandidateParams) => {
    const { activeProjectId, searchCandidateResults } = get();
    if (activeProjectId) {
      const existingSearchResult = searchCandidateResults?.find(result => {
        if (result.offset === offset && result.pageSize === SEARCH_CANDIDATES_PAGE_SIZE) {
          return result.candidates;
        }
      });
      if (existingSearchResult && !refresh) {
        return;
      }
      const { size, candidates, projectId } = await searchCandidatesByProject({
        projectId: activeProjectId,
        offset: offset,
        size: SEARCH_CANDIDATES_PAGE_SIZE,
      });
      if (activeProjectId === projectId) {
        const { searchCandidateResults } = get();
        set({
          searchCandidateResults: [
            {
              offset: offset,
              pageSize: size || SEARCH_CANDIDATES_PAGE_SIZE,
              candidates: candidates || [],
            },
            ...(searchCandidateResults || []),
          ],
        });
      }
    }
  },
  fetchCandidatePipeline: async () => {
    const { activeProjectId } = get();
    if (activeProjectId) {
      const { candidatePipeline } = await getCandidatePipeline({ projectId: activeProjectId });
      if (candidatePipeline?.projectId === activeProjectId) {
        set({
          candidatePipeline,
        });
      }
      return candidatePipeline;
    }
  },
  addCandidateToPipeline: async (candidateId: string, stage: PipelineStageEnum, notes?: string) => {
    const { activeProjectId } = get();
    if (activeProjectId) {
      await batchAddCandidatesToPipeline({
        candidateStageItems: [
          {
            candidateStage: {
              stage,
              notes,
            },
            candidateId,
          },
        ],
        projectId: activeProjectId,
      });
    }
  },
  batchUpdateCandidateStages: async (
    candidateStageItems: { candidateId: string; stage: PipelineStageEnum; notes?: string }[]
  ) => {
    const { activeProjectId } = get();
    if (activeProjectId) {
      await batchUpdateCandidateStages({
        candidateStageItems: candidateStageItems.map(item => ({
          candidateStage: {
            stage: item.stage,
            notes: item.notes,
          },
          candidateId: item.candidateId,
        })),
        projectId: activeProjectId,
      });
    }
  },
  batchUploadCandidates: async (candidates: FileContent[], candidateStage?: CandidateStage) => {
    const { activeProjectId } = get();
    if (activeProjectId) {
      await batchUploadCandidates({ projectId: activeProjectId, candidates, candidateStage });
    }
  },
});
