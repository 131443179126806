import { DropdownOption, MessageLength } from 'api-types';
import moment from 'moment';

export const MESSAGE_CONTENT_LENGTH = {
  [MessageLength.VeryShort]: {
    title: 'Very Short',
    content: 'Keep message length around 50-100 words.',
  },
  [MessageLength.Short]: {
    title: 'Short',
    content: 'Keep message length around 100-200 words.',
  },
  [MessageLength.Medium]: {
    title: 'Medium',
    content: 'Keep message length around 200-300 words.',
  },
  [MessageLength.Long]: {
    title: 'Long',
    content: 'Keep message length around 300-500 words.',
  },
};

const MAX_NUMBER_OF_DAYS = 25;
const arr: number[] = [];
for (let i = 0; i <= MAX_NUMBER_OF_DAYS; i++) {
  arr.push(i);
}

export const INTERVAL_OPTIONS: DropdownOption[] = [
  ...arr.map((val, index) => {
    const interval = val === 0 ? 'PT0S' : `P${val.toString()}D`;
    return {
      value: interval,
      displayValue:
        val === 0
          ? moment.duration(interval, 'days').humanize(false)
          : moment.duration(interval, 'days').humanize(true),
    };
  }),
];

export const MAIN_INTERVAL_DROPDOWN_OPTIONS = [0, 3, 7, 10, 14].map(val => {
  if (val === 0) {
    return 'PT0S';
  }
  return `P${val.toString()}D`;
});

export const OUTREACH_TYPE_OPTIONS: { label: string; value: 'phone' | 'email' }[] = [
  { label: 'Phone', value: 'phone' },
  { label: 'Email', value: 'email' },
];

export const SAMPLE_QUESTIONS = [
  'Please tell us a bit more about your background.',
  'What are you looking for in a new role?',
  'Why are you looking to switch jobs?',
  'What projects/areas do you love spending time on?',
  'What are the things you want to work on in the future?',
  'What are you looking for in a company culture?',
];
