import { useMemo, useState } from 'react';
import { PipelineStageEnum } from 'api-types';
import { useSnackbar } from '../../../components/Snackbar/SnackbarContext';
import {
  getAllPipelineCandidates,
  getCandidateFromCandidatePipeline,
} from '../../outreach/pipeline/utils';
import { CandidatePipelineTable } from '../../outreach/pipeline/components/table/CandidatePipelineTable';
import { Drawer } from '@mui/material';
import styles from './phone-conversation.module.css';
import { EditableCandidateProfile } from '../../candidate/profile/EditableCandidateProfile';
import { useAppStore } from '../../../store/appStore';
import { upsertOutreachPhoneConversation } from '../../../api/api';
import { useCandidatesPipelineFetcherByProjectId } from '../../candidate/hooks/useCandidatesPipelineFetcher';
import { useBatchGetOutreachThreadsByProjectId } from '../../outreach/outreach-thread/hooks/useBatchGetOutreachThread';

export function PhoneConversationView({ selectedProjectId }: { selectedProjectId: string }) {
  const [openCandidateProfileId, setOpenCandidateProfileId] = useState('');
  const { showSuccessSnackbar } = useSnackbar();

  const { candidatePipeline, loading, refresh } = useCandidatesPipelineFetcherByProjectId(
    selectedProjectId,
    [PipelineStageEnum.Shortlist, PipelineStageEnum.OutreachInitialized]
  );
  const candidateIds = useMemo(
    () => getAllPipelineCandidates(candidatePipeline)?.map(({ id }) => id!),
    [candidatePipeline]
  );

  const { candidateOutreachThreads } = useBatchGetOutreachThreadsByProjectId(
    selectedProjectId,
    candidateIds
  );

  const onCandidatePhoneSummarySubmit = async (candidateId: string, phoneSummary: string) => {
    await upsertOutreachPhoneConversation({
      projectId: selectedProjectId,
      candidateId,
      phoneConversation: {
        summary: phoneSummary,
      },
    });
    showSuccessSnackbar('Phone conversation updated successfully');
    await refresh();
  };

  const openedCandidate = getCandidateFromCandidatePipeline(
    candidatePipeline,
    openCandidateProfileId
  );

  return (
    <>
      <>
        <CandidatePipelineTable
          candidatePipeline={candidatePipeline}
          onCandidateProfileOpen={id => setOpenCandidateProfileId(id)}
          candidateOutreachThreads={candidateOutreachThreads}
        />
        <Drawer
          anchor={'right'}
          open={Boolean(openCandidateProfileId)}
          onClose={() => setOpenCandidateProfileId('')}
        >
          <div className={styles.drawerContainer}>
            {openedCandidate && (
              <EditableCandidateProfile
                candidate={openedCandidate}
                onCandidatePhoneSummarySubmit={(summary: string) =>
                  onCandidatePhoneSummarySubmit(openCandidateProfileId, summary)
                }
                projectId={selectedProjectId}
                renderMessages
                renderPhoneSummary
                renderReasoning
              />
            )}
          </div>
        </Drawer>
      </>
    </>
  );
}
