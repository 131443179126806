import { pascalCase } from 'change-case';
import moment from 'moment';

export function humanizeDateRange(startDate: string, endDate: string | undefined) {
  return `${moment(startDate).format('MMM YYYY')} - ${endDate ? moment(endDate).format('MMM YYYY') : 'Present'}`;
}

export function humanizeDegree(degree: string, fieldOfStudy: string | undefined) {
  return pascalCase(degree) + (fieldOfStudy ? ` in ${fieldOfStudy}` : '');
}
