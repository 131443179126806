import {
  Candidate,
  CandidateOutreachStats,
  CandidatePipeline,
  OutreachThread,
  PipelineStageEnum,
} from 'api-types';
import { getCandidateOutreachStage } from '../../utils';
import { CandidatePipelineTableRow } from './CandidatePipelineTable';

export const getCandidateStage = (
  stage: PipelineStageEnum | undefined,
  outreachThread: OutreachThread | undefined
): string | PipelineStageEnum | undefined => {
  if (PipelineStageEnum.OutreachInitialized && outreachThread) {
    return getCandidateOutreachStage(outreachThread);
  }
  return stage;
};

export function getCandidateRowById(
  candidatePipeline?: CandidatePipeline,
  candidateOutreachStats?: CandidateOutreachStats[],
  candidateOutreachThreads?: OutreachThread[],
  additionalCandidates?: Candidate[]
) {
  const candidateRowById: {
    [index: string]: CandidatePipelineTableRow;
  } = {};
  const candidateStatsById: { [index: string]: CandidateOutreachStats } = {};
  candidateOutreachStats?.forEach(stat => {
    candidateStatsById[stat.candidateId!] = stat;
  });

  for (const pipelineStage of candidatePipeline?.pipelineStages || []) {
    for (const candidate of pipelineStage.candidates || []) {
      candidateRowById[candidate.id!] = {
        candidate,
        stage: pipelineStage.stage!,
        candidateOutreachThread: candidateOutreachThreads?.find(
          thread => thread.candidateId === candidate.id
        ),
        stat: candidateStatsById[candidate.id!],
      };
    }
  }
  additionalCandidates?.forEach(candidate => {
    candidateRowById[candidate.id!] = {
      candidate,
    };
  });

  return candidateRowById;
}
