import styles from './outreach-editor.module.css';
import { OutreachStage as IOutreachStage } from 'api-types';
import { FormControl, FormHelperText, FormLabel, Option, Select, Tooltip } from '@mui/joy';
import { useState } from 'react';
import { Box } from '@mui/material';
import {
  INTERVAL_OPTIONS,
  MAIN_INTERVAL_DROPDOWN_OPTIONS,
  OUTREACH_TYPE_OPTIONS,
} from './constants';
import { DateInterval } from './components/DateInterval';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import IconButton from '@mui/joy/IconButton';
import { grey } from '@mui/material/colors';
import { EmailStageEditor } from './email-stage-editor/EmailStageEditor';
import { PhoneStageEditor } from './PhoneStageEditor';

export interface OutreachConfigStageProps {
  currentOutreachStage?: IOutreachStage;
  stageIndex: number;
  totalNumStages: number;
  stageRef: React.Ref<StageImperativeHandler>;
  onRemoveStage: (index: number) => void;
}

export interface StageImperativeHandler {
  getStageConfig: () => IOutreachStage;
}

export type OutreachType = 'phone' | 'email';

export function OutreachStage(props: OutreachConfigStageProps) {
  const { currentOutreachStage, stageIndex, onRemoveStage, stageRef } = props;
  const initialOutreachType = currentOutreachStage?.messageConfig
    ? 'email'
    : currentOutreachStage?.phoneConfig
      ? 'phone'
      : undefined;
  const [outreachType, setOutreachType] = useState<OutreachType>(
    initialOutreachType as OutreachType
  );
  const [sendInterval, setSendInterval] = useState(currentOutreachStage?.sendInterval);

  const getOutreachEditor = () => {
    switch (outreachType) {
      case 'email':
        return <EmailStageEditor {...props} sendInterval={sendInterval} />;
      case 'phone':
        return (
          <PhoneStageEditor
            currentOutreachStage={currentOutreachStage}
            stageRef={stageRef}
            sendInterval={sendInterval}
          />
        );
      default:
        return null;
    }
  };

  const renderStageEditor = () => {
    return (
      <div>
        <Box display="flex" justifyContent="space-between">
          <FormControl className={styles.formInput}>
            <FormLabel>Select Outreach Type</FormLabel>
            <Select
              value={outreachType}
              onChange={(e, newValue) => setOutreachType(newValue as OutreachType)}
              sx={{ width: 240 }}
            >
              {OUTREACH_TYPE_OPTIONS.map(option => (
                <Option key={option.value} value={option.value}>
                  {option.label}
                </Option>
              ))}
            </Select>
          </FormControl>
          <Tooltip title="Delete Stage" variant="solid" size="sm">
            <IconButton
              variant="plain"
              onClick={() => onRemoveStage(stageIndex)}
              sx={{ height: 'min-content' }}
            >
              <DeleteOutlineIcon style={{ color: grey[600] }} />
            </IconButton>
          </Tooltip>
        </Box>
        <FormControl sx={{ marginBottom: '20px' }}>
          <DateInterval
            label="Send Interval"
            value={sendInterval}
            onChange={value => setSendInterval(value as string)}
            options={INTERVAL_OPTIONS}
            defaultOptions={MAIN_INTERVAL_DROPDOWN_OPTIONS}
          />
          <FormHelperText>
            {stageIndex === 0
              ? 'For first outreach stage, the interval is relative to the when the outreach campaign is initiated.'
              : `The interval is relative to the previous
          outreach stage.`}
          </FormHelperText>
        </FormControl>
      </div>
    );
  };

  return (
    <div className={styles.container}>
      <div className={styles.editorContainer}>
        <div className={styles.editorForm}>
          {renderStageEditor()}
          {getOutreachEditor()}
        </div>
      </div>
    </div>
  );
}
