import { useEffect, useMemo, useState } from 'react';
import { useAppStore } from '../../../store/appStore';
import { CandidatePipeline, PipelineStageEnum } from 'api-types';
import { getCandidatePipeline } from '../../../api/api';

export function useCandidatesPipelineFetcher(stages?: PipelineStageEnum[]) {
  const candidatePipeline = useAppStore(state => state.candidatePipeline);
  const fetchCandidatePipeline = useAppStore(state => state.fetchCandidatePipeline);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();

  const fetchCandidatePipelineAsync = async () => {
    setLoading(true);
    try {
      await fetchCandidatePipeline();
    } catch (e) {
      console.error(e);
      setError('Failed to fetch candidates');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCandidatePipelineAsync();
  }, []);

  if (stages && candidatePipeline?.pipelineStages) {
    candidatePipeline.pipelineStages = candidatePipeline.pipelineStages.filter(pipelineStage => {
      return stages.includes(pipelineStage.stage!);
    });
  }

  return {
    candidatePipeline,
    loading,
    error,
    refresh: fetchCandidatePipelineAsync,
  };
}

export function useCandidatesPipelineFetcherByProjectId(
  projectId: string,
  stages?: PipelineStageEnum[]
) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | undefined>();
  const [candidatePipeline, setCandidatePipeline] = useState<CandidatePipeline | undefined>();

  const fetchCandidatePipelineAsync = async (projectId: string) => {
    setLoading(true);
    try {
      const { candidatePipeline } = await getCandidatePipeline({ projectId });
      setCandidatePipeline(candidatePipeline);
    } catch (e) {
      console.error(e);
      setError('Failed to fetch candidates');
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchCandidatePipelineAsync(projectId);
  }, [projectId]);

  let filteredCandidatePipeline = useMemo(() => {
    const arr: CandidatePipeline = {
      ...candidatePipeline,
      pipelineStages: [...(candidatePipeline?.pipelineStages || [])],
    };
    if (stages && candidatePipeline?.pipelineStages) {
      arr.pipelineStages = arr.pipelineStages.filter(pipelineStage => {
        return stages.includes(pipelineStage.stage!);
      });
    }
    return arr;
  }, [candidatePipeline]);

  return {
    candidatePipeline: filteredCandidatePipeline,
    loading,
    error,
    refresh: () => fetchCandidatePipelineAsync(projectId),
  };
}
