import { FormControl, FormLabel, Typography } from '@mui/joy';
import styles from '.././outreach-editor.module.css';
import { Input } from '../../../../components/Input';

interface TextEditorProps {
  subject: string;
  setSubject: (message: string) => void;
  message: string;
}

export function TextEditor({ subject, setSubject, message }: TextEditorProps) {
  return (
    <>
      <FormControl>
        <FormLabel>Subject</FormLabel>
        <Input value={subject} onChange={e => setSubject(e.target.value)} />
      </FormControl>

      <Typography component="div" level="body-md" className={styles.content}>
        <article dangerouslySetInnerHTML={{ __html: message }} />
      </Typography>
    </>
  );
}
