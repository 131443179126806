import { useEffect, useMemo, useState } from 'react';
import { useAppStore } from '../../../store/appStore';
import styles from './pipeline.module.css';
import { SpinnerLoader } from '../../../components/Loader/SpinnerLoader';
import { CandidatePipeline, PipelineStageEnum } from 'api-types';
import { Typography, Button } from '@mui/joy';
import {
  getAllPipelineCandidates,
  getOutreachedCandidates,
  getShortlistedCandidates,
} from './utils';
import { CandidatePipelineView } from './components/CandidatePipelineView';
import { useCandidatesPipelineFetcher } from '../../candidate/hooks/useCandidatesPipelineFetcher';
import { useBatchGetOutreachThreads } from '../outreach-thread/hooks/useBatchGetOutreachThread';

export function Pipeline() {
  const bulkSendOutreach = useAppStore(state => state.bulkSendOutreach);
  const outreach = useAppStore(state => state.outreach);

  const batchGetCandidateOutreachStats = useAppStore(state => state.batchGetCandidateOutreachStats);
  const candidateOutreachStats = useAppStore(state => state.candidateOutreachStats);
  const [updatingCandidateStages, setUpdatingCandidateStages] = useState(false);
  const [initializingOutreach, setInitializingOutreach] = useState(false);
  const [loadingOutreachStats, setLoadingOutreachStats] = useState(false);

  const getCandidateOutreachStats = async (candidatePipeline: CandidatePipeline) => {
    setLoadingOutreachStats(true);
    try {
      const outreachedCandidates = getOutreachedCandidates(candidatePipeline);
      await batchGetCandidateOutreachStats(outreachedCandidates?.map(({ id }) => id!));
    } finally {
      setLoadingOutreachStats(false);
    }
    return candidatePipeline;
  };

  const {
    candidatePipeline,
    loading: loadingCandidatePipeline,
    refresh: refreshPipeline,
  } = useCandidatesPipelineFetcher();

  const allCandidates = getAllPipelineCandidates(candidatePipeline);
  const shortlistedCandidates = getShortlistedCandidates(candidatePipeline);

  const handleOutreachInitialize = async () => {
    if (!shortlistedCandidates?.length) return;
    try {
      setInitializingOutreach(true);
      setUpdatingCandidateStages(true);
      await bulkSendOutreach(shortlistedCandidates.map(candidate => candidate.id!));
      await refreshPipeline();
    } finally {
      setUpdatingCandidateStages(false);
      setInitializingOutreach(false);
    }
  };

  useEffect(() => {
    if (candidatePipeline) {
      getCandidateOutreachStats(candidatePipeline);
    }
  }, [candidatePipeline]);

  const candidateIds = useMemo(
    () => getAllPipelineCandidates(candidatePipeline)?.map(({ id }) => id!),
    [candidatePipeline]
  );

  const { candidateOutreachThreads } = useBatchGetOutreachThreads(candidateIds);

  if (candidatePipeline?.pipelineStages) {
    candidatePipeline.pipelineStages = candidatePipeline.pipelineStages.filter(stage => {
      if (
        stage.stage === PipelineStageEnum.Shortlist ||
        stage.stage === PipelineStageEnum.OutreachInitialized
      ) {
        return true;
      }
    });
  }

  return (
    <div className={styles.pipelineContainer}>
      {loadingCandidatePipeline || loadingOutreachStats ? (
        <SpinnerLoader />
      ) : (
        <>
          <div className={styles.pipelineActionBar}>
            <Button
              color="primary"
              onClick={handleOutreachInitialize}
              loading={initializingOutreach}
              disabled={updatingCandidateStages || !(outreach && shortlistedCandidates?.length)}
            >
              Initiate Outreach
            </Button>
          </div>
          {allCandidates?.length ? (
            <CandidatePipelineView
              candidatePipeline={candidatePipeline}
              outreach={outreach}
              candidateOutreachStats={candidateOutreachStats}
              candidateOutreachThreads={candidateOutreachThreads}
            />
          ) : (
            <div className={styles.emptyPipeline}>
              <Typography level="body-md" color="neutral">
                Pipeline is empty, add some candidates to get started.
              </Typography>
            </div>
          )}
        </>
      )}
    </div>
  );
}
