import { CandidateItem } from 'api-types';
import styles from '.././candidate.module.css';
import { Button, Tab, TabPanel, Tabs, Textarea, Typography } from '@mui/joy';
import { useEffect, useState } from 'react';
import { useLatestOutreachMessageByProjectId } from '../../outreach/outreach-thread/hooks/useLatestOutreachMessage';
import { SpinnerLoader } from '../../../components/Loader/SpinnerLoader';
import { usePhoneConversationSummaryByProjectId } from '../../outreach/outreach-thread/hooks/usePhoneConversationSummary';
import { TabEnum } from './CandidateProfile';
import { TabList } from '../../../components/Tabs/TabList';
import { CandidateProfileDetails } from './components/CandidateProfileDetails';
import { CandidateHeaderDetails } from './components/CandidateHeaderDetails';
import { ContentBox } from './components/ContentBox';

export interface EditConfig {
  editable?: boolean;
  onEdit?: (candidate: CandidateItem) => void;
}

interface CandidateProfileProps {
  candidate: CandidateItem;
  projectId: string;
  onCandidatePhoneSummarySubmit?: (summary: string) => void;
  onCandidateReasoningSubmit?: (reasoningNotes: string) => void;
  renderMessages?: boolean;
  renderReasoning?: boolean;
  renderPhoneSummary?: boolean;
}

export function EditableCandidateProfile({
  candidate,
  projectId,
  onCandidatePhoneSummarySubmit,
  onCandidateReasoningSubmit,
  renderMessages,
  renderReasoning,
  renderPhoneSummary,
}: CandidateProfileProps) {
  const [selectedTab, setSelectedTab] = useState<TabEnum>(
    renderReasoning ? TabEnum.Reasoning : TabEnum.Profile
  );
  const {
    loading: loadingMessage,
    message,
    subject,
  } = useLatestOutreachMessageByProjectId(projectId, candidate.id!);

  const [reasoning, setReasoning] = useState(candidate.summaryNotes);
  const { loading: loadingPhoneSummary, phoneConversation } =
    usePhoneConversationSummaryByProjectId(projectId, candidate.id!);
  const [phoneSummary, setPhoneSummary] = useState<string>();

  useEffect(() => {
    if (phoneConversation) {
      setPhoneSummary(phoneConversation.summary);
    }
  }, [phoneConversation]);

  return (
    <div className={styles.profileContainer}>
      <CandidateHeaderDetails candidate={candidate} />
      <Tabs
        value={selectedTab}
        sx={{ backgroundColor: 'transparent' }}
        onChange={(e, val: string | number | null) => setSelectedTab(val as typeof selectedTab)}
      >
        <TabList className={styles.tabList} size="sm">
          {renderReasoning && <Tab value={TabEnum.Reasoning}>AI Reasoning</Tab>}
          <Tab value={TabEnum.Profile}>Profile</Tab>
          {renderMessages && <Tab value={TabEnum.Message}>Messages</Tab>}
          {renderPhoneSummary && <Tab value={TabEnum.PhoneSummary}>Phone Summary</Tab>}
        </TabList>
        <TabPanel value="profile">
          <CandidateProfileDetails candidate={candidate} />
        </TabPanel>
        <TabPanel value="reasoning">
          <>
            <ContentBox content={candidate.summaryNotes} />
            <div className={styles.editableForm}>
              <Typography level="title-md" gutterBottom>
                Edit AI Reasoning
              </Typography>
              <Textarea
                value={reasoning}
                onChange={e => setReasoning(e.target.value)}
                minRows={4}
              />
            </div>
            <Button onClick={() => onCandidateReasoningSubmit?.(reasoning || '')}>Submit</Button>
          </>
        </TabPanel>
        <TabPanel value="message">
          {loadingMessage ? (
            <SpinnerLoader />
          ) : (
            message && (
              <>
                <Typography level="title-md" gutterBottom>
                  Subject:{' '}
                  <Typography component={'span'} level="body-md">
                    {subject}
                  </Typography>
                </Typography>
                <ContentBox content={message.message} />
              </>
            )
          )}
        </TabPanel>
        <TabPanel value="phone-summary">
          {loadingMessage ? (
            <SpinnerLoader />
          ) : (
            phoneConversation && <ContentBox content={phoneConversation.summary} />
          )}
          <>
            <div className={styles.editableForm}>
              <Typography level="title-md" gutterBottom>
                Edit Phone Call Summary
              </Typography>
              <Textarea
                value={phoneSummary}
                onChange={e => setPhoneSummary(e.target.value)}
                minRows={4}
              />
            </div>
            <Button onClick={() => onCandidatePhoneSummarySubmit?.(phoneSummary || '')}>
              Submit
            </Button>
          </>
        </TabPanel>
      </Tabs>
    </div>
  );
}
