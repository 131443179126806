import { CandidateItem } from 'api-types';
import styles from '.././candidate.module.css';
import { Tab, TabPanel, Tabs, Typography } from '@mui/joy';
import { useState } from 'react';
import { SpinnerLoader } from '../../../components/Loader/SpinnerLoader';
import { useLatestOutreachMessage } from '../../outreach/outreach-thread/hooks/useLatestOutreachMessage';
import { usePhoneConversationSummary } from '../../outreach/outreach-thread/hooks/usePhoneConversationSummary';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { TabList } from '../../../components/Tabs/TabList';
import { CandidateProfileDetails } from './components/CandidateProfileDetails';
import { CandidateHeaderDetails } from './components/CandidateHeaderDetails';
import { ContentBox } from './components/ContentBox';

interface CandidateProfileProps {
  candidate: CandidateItem;
  renderMessages?: boolean;
  renderReasoning?: boolean;
  renderPhoneSummary?: boolean;
}

export enum TabEnum {
  Profile = 'profile',
  Message = 'message',
  Reasoning = 'reasoning',
  PhoneSummary = 'phone-summary',
}

export function CandidateProfile({
  candidate,
  renderMessages,
  renderReasoning,
  renderPhoneSummary,
}: CandidateProfileProps) {
  const [selectedTab, setSelectedTab] = useState<TabEnum>(
    renderReasoning ? TabEnum.Reasoning : TabEnum.Profile
  );

  const { loading: loadingMessage, message, subject } = useLatestOutreachMessage(candidate.id!);
  const { loading: loadingPhoneSummary, phoneConversation } = usePhoneConversationSummary(
    candidate.id!
  );

  return (
    <div className={styles.profileContainer}>
      <CandidateHeaderDetails candidate={candidate} />
      <Tabs
        value={selectedTab}
        sx={{ backgroundColor: 'transparent' }}
        onChange={(e, val: string | number | null) => setSelectedTab(val as typeof selectedTab)}
      >
        <TabList className={styles.tabList} size="sm">
          {renderReasoning && (
            <Tab value={TabEnum.Reasoning}>
              <>
                <AutoAwesomeOutlinedIcon fontSize="small" />
                AI Reasoning
              </>
            </Tab>
          )}
          <Tab value={TabEnum.Profile}>Profile</Tab>
          {renderMessages && <Tab value={TabEnum.Message}>Messages</Tab>}
          {renderPhoneSummary && <Tab value={TabEnum.PhoneSummary}>Phone Summary</Tab>}
        </TabList>
        <TabPanel value={TabEnum.Profile}>
          <CandidateProfileDetails candidate={candidate} />
        </TabPanel>
        <TabPanel value={TabEnum.Message}>
          {loadingMessage ? (
            <SpinnerLoader />
          ) : (
            message && (
              <>
                <Typography level="title-md" gutterBottom>
                  Subject:{' '}
                  <Typography component={'span'} level="body-md">
                    {subject}
                  </Typography>
                </Typography>
                <ContentBox content={message.message} />
              </>
            )
          )}
        </TabPanel>
        <TabPanel value={TabEnum.Reasoning}>
          <ContentBox content={candidate.summaryNotes} />
        </TabPanel>
        <TabPanel value={TabEnum.PhoneSummary}>
          {loadingPhoneSummary ? (
            <SpinnerLoader />
          ) : (
            <ContentBox content={phoneConversation?.summary} />
          )}
        </TabPanel>
      </Tabs>
    </div>
  );
}
