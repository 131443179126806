import { extendTheme } from '@mui/joy';

extendTheme({
  components: {
    JoyInput: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === 'neutral' && {
            backgroundColor: 'rgb(255, 255, 255) !important',
            border: 'red',
          }),
        }),
      },
    },
    JoyTextarea: {
      styleOverrides: {
        root: {
          ...{
            backgroundColor: 'rgb(255, 255, 255) !important',
            border: 'red',
          },
        },
      },
    },
    JoyAutocomplete: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          ...(ownerState.color === 'neutral' && {
            border: 'red',
            backgroundColor: 'rgb(255, 255, 255) !important',
          }),
        }),
      },
    },
  },
});
