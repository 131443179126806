import { useRef } from 'react';

export function useScrollToBottom() {
  const containerRef = useRef<HTMLDivElement>(null);

  function scrollToBottom(behavior?: ScrollBehavior) {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: containerRef.current.scrollHeight,
        behavior,
      });
    }
  }
  return { containerRef, scrollToBottom };
}
