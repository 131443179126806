import { useImperativeHandle, useState } from 'react';
import styles from './outreach-editor.module.css';
import { Button, FormControl, IconButton, Input, Textarea, Typography } from '@mui/joy';
import { SAMPLE_QUESTIONS } from './constants';
import { Box } from '@mui/material';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { grey } from '@mui/material/colors';
import { StageImperativeHandler } from './OutreachStage';
import { OutreachStage as IOutreachStage } from 'api-types';

export function PhoneStageEditor({
  currentOutreachStage,
  stageRef,
  sendInterval,
}: {
  currentOutreachStage?: IOutreachStage;
  stageRef: React.Ref<StageImperativeHandler>;
  sendInterval: string | undefined;
}) {
  const [questions, setQuestions] = useState<string[]>(
    currentOutreachStage?.phoneConfig?.questions || []
  );

  const onRemoveQuestion = (index: number) => {
    setQuestions(questions.filter((_, i) => i !== index));
  };

  const onQuestionChange = (index: number, value: string) => {
    const newQuestions = [...questions];
    newQuestions[index] = value;
    setQuestions(newQuestions);
  };

  useImperativeHandle(stageRef, () => ({
    getStageConfig: () => ({
      id: currentOutreachStage?.id,
      sendInterval: sendInterval,
      outreachType: 'phone',
      phoneConfig: {
        questions: questions.filter(q => q !== '').map(q => q.trim()),
      },
    }),
  }));

  return (
    <>
      <Typography level="body-md" color="neutral" gutterBottom>
        For phone calls we ask candidates a standard set of questions to gauge their fit for the
        role. These include:
      </Typography>
      {SAMPLE_QUESTIONS.map((question, index) => (
        <Typography level="body-md" color="neutral" key={index} className={styles.question}>
          {index + 1}. {question}
        </Typography>
      ))}
      <div className={styles.messageToggleContainer}>
        {questions && questions.length > 0 && (
          <>
            <Typography level="title-sm" fontWeight={'lg'} color="neutral" gutterBottom>
              Additional questions
            </Typography>
            {questions.map((question, index) => (
              <Box
                display="flex"
                justifyContent="space-between"
                sx={{ marginBottom: '20px', width: 400 }}
              >
                <FormControl>
                  <Textarea
                    value={question}
                    onChange={e => onQuestionChange(index, e.target.value)}
                    sx={{ width: 240 }}
                  />
                </FormControl>
                <IconButton variant="plain" onClick={() => onRemoveQuestion(index)}>
                  <DeleteOutlineIcon style={{ color: grey[600] }} />
                </IconButton>
              </Box>
            ))}
          </>
        )}

        <Button
          variant="outlined"
          onClick={() => setQuestions([...questions, ''])}
          sx={{ marginBottom: '20px' }}
        >
          Add additional question
        </Button>
      </div>
    </>
  );
}
