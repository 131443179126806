import { Card, CardContent, Typography } from '@mui/joy';
import styles from './component.module.css';

export interface CardSelectOption {
  value: string;
  content?: string;
  heading: string;
}

interface CardSelectProps {
  options: CardSelectOption[];
  label: string;
  value: string | undefined;
  onChange: (value: string) => void;
}

export function CardSelect({ options, label, value, onChange }: CardSelectProps) {
  const handleOnChange = (value: string) => {
    onChange(value);
  };
  return (
    <>
      <Typography level="title-sm" fontWeight={'lg'} color="neutral" gutterBottom>
        {label}
      </Typography>
      <div className={styles.cardContainer}>
        {options.map((option, index) => (
          <Card
            size="sm"
            invertedColors={value === option.value}
            key={index}
            variant={value === option.value ? 'solid' : 'outlined'}
            className={styles.card}
            sx={{
              width: 320,
              ...(option.value !== value && { backgroundColor: 'rgb(255, 255, 255)' }),
              '&:hover': { boxShadow: 'md', borderColor: 'neutral.outlinedHoverBorder' },
            }}
            onClick={() => handleOnChange(option.value)}
          >
            <CardContent>
              <Typography level="title-sm">{option.heading}</Typography>
              {option.content && (
                <Typography level="body-sm" color={'neutral'}>
                  {option.content}
                </Typography>
              )}
            </CardContent>
          </Card>
        ))}
      </div>
    </>
  );
}
