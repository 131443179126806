import {
  Candidate,
  CandidatePipeline,
  OutreachMessage,
  OutreachPhoneConversation,
  OutreachThread,
  PipelineStageEnum,
} from 'api-types';

export const getOutreachedCandidates = (candidatePipeline: CandidatePipeline | undefined) =>
  candidatePipeline?.pipelineStages?.find(({ stage }) => stage !== PipelineStageEnum.Shortlist)
    ?.candidates;

export const getShortlistedCandidates = (candidatePipeline: CandidatePipeline | undefined) =>
  candidatePipeline?.pipelineStages?.find(({ stage }) => stage === PipelineStageEnum.Shortlist)
    ?.candidates;

export const getAllPipelineCandidates = (candidatePipeline: CandidatePipeline | undefined) =>
  candidatePipeline?.pipelineStages?.reduce(
    (acc, { candidates }) => acc.concat(...(candidates || [])),
    [] as Candidate[]
  ) || [];

export function getCandidateFromCandidatePipeline(
  candidatePipeline: CandidatePipeline | undefined,
  candidateId: string
) {
  return candidatePipeline?.pipelineStages
    ?.reduce((acc, { candidates }) => acc.concat(...(candidates || [])), [] as Candidate[])
    .find(candidate => candidate.id === candidateId);
}

const getOutreachTypeCreatedAt = (outreachType: OutreachPhoneConversation | OutreachMessage) => {
  if ((outreachType as OutreachPhoneConversation).createdAt) {
    return (outreachType as OutreachPhoneConversation).createdAt;
  }
  if ((outreachType as OutreachMessage).sentTime) {
    return (outreachType as OutreachMessage).sentTime;
  }
};

function isOutreachPhoneConversation(
  outreach: OutreachMessage | OutreachPhoneConversation
): outreach is OutreachPhoneConversation {
  return 'summary' in outreach;
}

function isOutreachMessage(
  outreach: OutreachMessage | OutreachPhoneConversation
): outreach is OutreachPhoneConversation {
  return 'message' in outreach || 'sentTime' in outreach || 'senderId' in outreach;
}

export const getCandidateOutreachStage = (outreachThread: OutreachThread) => {
  const phoneConversations = outreachThread.outreachPhoneConversations || [];
  const outreachMessages = outreachThread.outreachMessages || [];
  const allCommunication: (OutreachMessage | OutreachPhoneConversation)[] = [
    ...phoneConversations,
    ...outreachMessages,
  ];
  allCommunication.sort((a, b) => {
    let aCreatedAt = getOutreachTypeCreatedAt(a);
    let bCreatedAt = getOutreachTypeCreatedAt(b);
    return new Date(bCreatedAt!).getTime() - new Date(aCreatedAt!).getTime();
  });
  const latestComm = allCommunication.find(comm => {
    if (isOutreachPhoneConversation(comm)) {
      return comm?.summary;
    }
    if (isOutreachMessage(comm)) {
      return comm?.message;
    }
  });
  if (!latestComm) {
    return '-';
  }
  if (isOutreachMessage(latestComm)) {
    return `Stage - ${allCommunication.length} (Email)`;
  }
  if (isOutreachPhoneConversation(latestComm)) {
    return `Stage - ${allCommunication.length} (Phone)`;
  }
  return '-';
};
