import { Typography } from '@mui/joy';

import styles from '../../candidate.module.css';

export function ContentBox({ content }: { content: string | undefined }) {
  return (
    <Typography component="div" level="body-md" className={styles.messageContent}>
      <article dangerouslySetInnerHTML={{ __html: content || '' }} />
    </Typography>
  );
}
