import { Button, Checkbox, Table } from '@mui/joy';
import {
  Candidate,
  CandidateOutreachStats,
  CandidatePipeline,
  Outreach,
  OutreachThread,
  PipelineStageEnum,
} from 'api-types';
import { Chip } from '../../../../../components/Chip';
import {
  ColumnFiltersState,
  OnChangeFn,
  RowSelectionState,
  createColumnHelper,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  useReactTable,
} from '@tanstack/react-table';
import { useEffect, useMemo, useState } from 'react';
import { PipelineCreateFilterButton, getUnsetFilters } from '../filter/PipelineCreateFilterButton';
import { RepliedChip, RepliedEnum, StageChip, StatusChip, StatusEnum } from '../PipelineChips';
import styles from '.././pipeline-table.module.css';
import { getCandidateOutreachStage } from '../../utils';
import {
  hasEmailReplyOnOutreachThread,
  hasPhoneConversationOnOutreachThread,
  isOutreachThreadFinished,
} from '../../../utils';
import { FilterConfig, RepliedFilter, StageFilter, StatusFilter } from '../filter/FilterComponents';
import { PipelineFilterButton } from '../filter/PipelineFilterButton';
import { PIPELINE_STAGE_TO_USER_STRINGS } from '../../constants';
import { getCandidateRowById, getCandidateStage } from './utils';

export interface CandidatePipelineTableRow {
  candidate: Candidate;
  stage?: PipelineStageEnum;
  stat?: CandidateOutreachStats;
  candidateOutreachThread?: OutreachThread;
}

const columnHelper = createColumnHelper<CandidatePipelineTableRow>();

interface CandidatePipelineTableProps {
  candidatePipeline?: CandidatePipeline;
  candidateOutreachStats?: CandidateOutreachStats[];
  candidateOutreachThreads?: OutreachThread[];
  additionalCandidates?: Candidate[];
  outreach?: Outreach;
  onCandidateProfileOpen?: (candidateId: string) => void;
  tableConfig?: TableConfig;
}

interface TableConfig {
  onCandidateSelection?: (selectedCandidateIds: string[]) => void;
}

export function CandidatePipelineTable({
  candidatePipeline,
  candidateOutreachStats,
  candidateOutreachThreads,
  outreach,
  additionalCandidates,
  onCandidateProfileOpen,
  tableConfig,
}: CandidatePipelineTableProps) {
  const [columnsFiltersState, setColumnsFiltersState] = useState<ColumnFiltersState>([]);
  const [rowSelection, setRowSelection] = useState<RowSelectionState>({});

  const getStatus = (stage: PipelineStageEnum | string, candidate: Candidate) => {
    if (stage === PipelineStageEnum.Shortlist) {
      return StatusEnum.NotInitialized;
    }
    const outreachThread = candidateOutreachThreads?.find(
      thread => thread.candidateId === candidate.id
    );
    if (outreachThread) {
      if (isOutreachThreadFinished(outreachThread, outreach)) {
        return StatusEnum.Finished;
      }
      return StatusEnum.InProcess;
    }

    return StatusEnum.NotInitialized;
  };

  const getReplied = (candidateOutreachThread?: OutreachThread) => {
    if (!candidateOutreachThread) {
      return RepliedEnum.NotReplied;
    }
    if (hasPhoneConversationOnOutreachThread(candidateOutreachThread)) {
      return RepliedEnum.Interested;
    }
    if (hasEmailReplyOnOutreachThread(candidateOutreachThread)) {
      return RepliedEnum.No;
    }
    return RepliedEnum.NotReplied;
  };

  const columnConfigs = useMemo(() => {
    const colConfigs: any = [
      columnHelper.accessor('candidate.name', {
        cell: info => (
          <Button
            variant="plain"
            color="neutral"
            size="sm"
            sx={{ backgroundColor: 'transparent !important' }}
            onClick={() => onCandidateProfileOpen?.(info.row.original.candidate.id!)}
          >
            {info.getValue()}
          </Button>
        ),
        header: 'Candidate',
      }),
      columnHelper.accessor(row => getCandidateStage(row.stage, row.candidateOutreachThread), {
        id: 'stage',
        cell: info => <StageChip stage={info.getValue()} />,
        header: 'Stage',
        filterFn: 'equalsString',
      }),
    ];
    if (candidateOutreachStats) {
      colConfigs.push(
        columnHelper.accessor('stat.totalOpenCount', {
          cell: info => info.getValue(),
          header: 'Opens',
        }),
        columnHelper.accessor('stat.totalClickCount', {
          cell: info => info.getValue(),
          header: 'Clicks',
        })
      );
    }
    if (candidateOutreachThreads) {
      colConfigs.push(
        columnHelper.accessor(row => getReplied(row.candidateOutreachThread), {
          id: 'replied',
          cell: info => <RepliedChip replied={info.getValue()} />,
          header: 'Replied',
          filterFn: 'equalsString',
        })
      );
    }
    if (outreach) {
      colConfigs.push(
        columnHelper.accessor(row => getStatus(row.stage as PipelineStageEnum, row.candidate), {
          id: 'status',
          cell: info => <StatusChip status={info.getValue()} />,
          header: 'Status',
        })
      );
    }
    if (tableConfig?.onCandidateSelection) {
      colConfigs.unshift(
        columnHelper.accessor(() => {}, {
          id: 'select',
          cell: ({ row }) => (
            <div>
              <Checkbox
                {...{
                  checked: row.getIsSelected(),
                  disabled: !row.getCanSelect(),
                  indeterminate: row.getIsSomeSelected(),
                  onChange: row.getToggleSelectedHandler(),
                }}
              />
            </div>
          ),
          header: 'Select',
        })
      );
    }
    return colConfigs;
  }, [outreach, candidateOutreachStats, candidateOutreachThreads]);

  const filterConfigs: FilterConfig[] = [
    {
      id: 'stage',
      label: 'Stage',
      FilterComponent: StageFilter,
      getFilterValue: (value: string | PipelineStageEnum | undefined) => {
        return PIPELINE_STAGE_TO_USER_STRINGS[value as PipelineStageEnum] || value || '';
      },
    },
  ];

  if (outreach) {
    filterConfigs.push({
      id: 'status',
      label: 'Status',
      FilterComponent: StatusFilter,
    });
  }

  if (candidateOutreachThreads) {
    filterConfigs.push({
      id: 'replied',
      label: 'Replied',
      FilterComponent: RepliedFilter,
    });
  }

  const data = useMemo(
    () =>
      Object.values(
        getCandidateRowById(
          candidatePipeline,
          candidateOutreachStats,
          candidateOutreachThreads,
          additionalCandidates
        )
      ),
    [
      outreach,
      candidatePipeline,
      candidateOutreachStats,
      candidateOutreachThreads,
      additionalCandidates,
    ]
  );

  const handleRowSelection = (selectedRowsOb: Record<string, boolean>) => {
    const selectedRows = Object.keys(selectedRowsOb).filter(id => selectedRowsOb[id]);
    const selectedCandidateIds = [];
    for (const rowIndex of selectedRows) {
      const { candidate } = data[parseInt(rowIndex)];
      selectedCandidateIds.push(candidate.id!);
    }
    setRowSelection(selectedRowsOb);
    tableConfig?.onCandidateSelection?.(selectedCandidateIds);
    return;
  };

  useEffect(() => {
    if (tableConfig?.onCandidateSelection) {
      const selectedRows = Object.keys(rowSelection).filter(id => rowSelection[id]);
      const selectedCandidateIds = [];
      for (const rowIndex of selectedRows) {
        const { candidate } = data[parseInt(rowIndex)];
        selectedCandidateIds.push(candidate.id!);
      }
      tableConfig?.onCandidateSelection?.(selectedCandidateIds);
    }
  }, [rowSelection]);

  const tableInstance = useReactTable({
    data: data,
    columns: columnConfigs,
    state: {
      columnFilters: columnsFiltersState,
      rowSelection,
    },
    enableRowSelection: Boolean(tableConfig?.onCandidateSelection),
    onRowSelectionChange: handleRowSelection as OnChangeFn<Record<string, boolean>>,
    onColumnFiltersChange: setColumnsFiltersState,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
  });

  return (
    <>
      <div className={styles.filtersContainer}>
        {columnsFiltersState.map(filter => (
          <PipelineFilterButton
            tableInstance={tableInstance}
            filter={filter}
            filterConfigs={filterConfigs}
          />
        ))}
        {getUnsetFilters(filterConfigs, columnsFiltersState).length > 0 && (
          <PipelineCreateFilterButton
            tableInstance={tableInstance}
            columnsFiltersState={columnsFiltersState}
            filterConfigs={filterConfigs}
          />
        )}
      </div>

      <Table aria-label="table variants" variant={'plain'} color={'neutral'}>
        <thead>
          {tableInstance.getHeaderGroups().map(headerGroup => (
            <tr key={headerGroup.id}>
              {headerGroup.headers.map(header => (
                <th key={header.id}>
                  {flexRender(header.column.columnDef.header, header.getContext())}
                </th>
              ))}
            </tr>
          ))}
        </thead>
        <tbody>
          {tableInstance.getRowModel().rows.map(row => (
            <tr key={row.id}>
              {row.getVisibleCells().map(cell => (
                <td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}
