import { Typography } from '@mui/joy';
import { useUserAndProjectSelector } from '../hooks/useUserAndProjectSelector';
import { AutoComplete } from '../../../components/AutoComplete';
import styles from './phone-conversation.module.css';
import { PhoneConversationView } from './PhoneConversationView';

export function PhoneConversation() {
  const {
    selectedUserId,
    selectedProjectId,
    setSelectedProjectId,
    setSelectedUserId,
    allUsers,
    projects,
  } = useUserAndProjectSelector();

  return (
    <>
      <Typography level="title-md">Candidate Recommendations</Typography>
      <div className={styles.formInput}>
        <AutoComplete
          label="Select User"
          value={selectedUserId || ''}
          onChange={value => setSelectedUserId(value as string)}
          options={
            allUsers?.map(({ id, firstName, lastName }) => ({
              value: id,
              displayValue: `${firstName} ${lastName}`,
            })) || []
          }
        />
      </div>
      <div className={styles.formInput}>
        <AutoComplete
          label="Select Project"
          value={selectedProjectId || ''}
          onChange={value => setSelectedProjectId(value as string)}
          options={
            projects?.map(({ id, name }) => ({
              value: id,
              displayValue: name,
            })) || []
          }
        />
      </div>
      {selectedProjectId && selectedUserId && (
        <PhoneConversationView selectedProjectId={selectedProjectId} />
      )}
    </>
  );
}
