import { fetchEventSource } from '@microsoft/fetch-event-source';

export async function post(url = '', data = {}) {
  const response = await fetch(url, {
    method: 'POST',
    mode: 'cors',
    cache: 'no-cache',
    credentials: 'same-origin',
    headers: {
      'Content-Type': 'application/json',
    },
    referrerPolicy: 'no-referrer',
    body: JSON.stringify(data),
  });
  if (response.status === 401) {
    window.location.href = '/login';
  }
  return response.json();
}

export interface EventSourceCallbacks<T> {
  onMessage?: (data: T) => void;
  onError?: (err: Error) => void;
  onDone?: () => void;
}

const IS_RETRY_SUPPORTED = false;

// Retries not supported yet, hence doesn't retry failed requests.
export async function postStreamRequest<T = any>(
  url = '',
  data = {},
  callbacks?: EventSourceCallbacks<T>
) {
  const controller = new AbortController();
  fetchEventSource(url, {
    method: 'POST',
    headers: {
      Accept: 'text/event-stream',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
    signal: controller.signal,
    // Do not remove openWhenHidden True, without this streaming is paused on user tab changes and resumed when come back to tab.
    // We can't handle this since we don't support resuming connection in b/w.
    openWhenHidden: true,
    onmessage(event) {
      const data = JSON.parse(event.data);
      if (data.done) {
        controller.abort();
        callbacks?.onDone?.();
        return;
      }
      if (data.startedProcessing) {
        // Don't call handler if message is a status message
        return;
      }
      callbacks?.onMessage?.(data as T);
    },
    onerror(err) {
      console.error('There was an error from server', err);
      callbacks?.onError?.(err);
      // Always throw error from here, if retry is not supported.
      if (!IS_RETRY_SUPPORTED) {
        throw err;
      }
    },
    onclose() {
      controller.abort();
    },
  });
}

export const EventStreamContentType = 'text/event-stream';
