import { Stack } from '@mui/material';
import { useAppStore } from '../../../store/appStore';
import styles from './outreach.module.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { OUTREACH_EDITOR_PATH } from '../../../constants';
import { Typography, Button } from '@mui/joy';
import { CandidateUpload } from '../../candidate/upload/CandidateUpload';
import { Pipeline } from '../pipeline/Pipeline';

export function OutreachHome() {
  const project = useAppStore(state => state.project);

  const outreach = useAppStore(state => state.outreach);
  const navigate = useNavigate();
  const { pathname } = useLocation();

  const handleOutreachUpdate = () => {
    navigate(`${pathname}${OUTREACH_EDITOR_PATH}`);
  };

  return (
    <>
      <div className={styles.header}>
        <Typography level="title-md" color="neutral" fontWeight="lg">
          {project?.name}
        </Typography>
        {outreach && (
          <Stack direction={'row'} spacing={1}>
            <Button
              className={styles.button}
              variant="plain"
              color="neutral"
              onClick={handleOutreachUpdate}
            >
              Edit
            </Button>
            <CandidateUpload />
          </Stack>
        )}
      </div>
      {!outreach && (
        <div className={styles.outreachSetupBanner}>
          <div className={styles.outreachBannerContent}>
            <Typography level="body-md" className={styles.outreachBannerHeading}>
              You have not setup an outreach sequence yet, Setup one to begin your campaign.
            </Typography>
            <Button onClick={handleOutreachUpdate}>Setup Outreach Sequence</Button>
          </div>
        </div>
      )}
      <Pipeline />
    </>
  );
}
