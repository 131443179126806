import { Route, Routes } from 'react-router-dom';
import { ProjectSourceScreen } from '../project-home/ProjectSourceScreen';
import { OutreachRoutes } from '../../outreach/routes/OutreachRoutes';
import { OUTREACH_HOME_PATH } from '../../../constants';

export function ProjectRoutes() {
  return (
    <Routes>
      <Route path="/" element={<ProjectSourceScreen />} />
      <Route path={`${OUTREACH_HOME_PATH}/*`} element={<OutreachRoutes />} />
    </Routes>
  );
}
