import { useEffect, useState } from 'react';
import { useAppStore } from '../../../../store/appStore';
import { OutreachPhoneConversation } from 'api-types';
import { getAllOutreachPhoneConversations } from '../../../../api/api';

export function usePhoneConversationSummary(candidateId: string) {
  const getAllOutreachPhoneConversations = useAppStore(
    state => state.getAllOutreachPhoneConversations
  );
  const [loading, setLoading] = useState(false);

  const [phoneConversations, setPhoneConversations] = useState<OutreachPhoneConversation[]>();

  const fetchPhoneConversations = async () => {
    setLoading(true);
    try {
      const phoneConversations = await getAllOutreachPhoneConversations(candidateId!);
      setPhoneConversations(phoneConversations);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPhoneConversations();
  }, [candidateId]);

  const lastPhoneConversation = phoneConversations?.[phoneConversations.length - 1];

  return {
    phoneConversation: lastPhoneConversation,
    loading,
  };
}

export function usePhoneConversationSummaryByProjectId(projectId: string, candidateId: string) {
  const [loading, setLoading] = useState(false);

  const [phoneConversations, setPhoneConversations] = useState<OutreachPhoneConversation[]>();

  const fetchPhoneConversations = async () => {
    setLoading(true);
    try {
      const { phoneConversations } = await getAllOutreachPhoneConversations({
        projectId,
        candidateId,
      });
      setPhoneConversations(phoneConversations);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchPhoneConversations();
  }, [projectId, candidateId]);

  const lastPhoneConversation = phoneConversations?.[phoneConversations.length - 1];

  return {
    phoneConversation: lastPhoneConversation,
    loading,
  };
}
