import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import styles from './outreach-editor.module.css';
import { useAppStore } from '../../../store/appStore';
import { Button, Snackbar, Step, StepIndicator, Stepper, Typography } from '@mui/joy';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { OUTREACH_HOME_PATH } from '../../../constants';
import { OutreachStage, OutreachType, StageImperativeHandler } from './OutreachStage';
import { OutreachStage as IOutreachStage } from 'api-types';
import AddIcon from '@mui/icons-material/AddOutlined';
import { useSnackbar } from '../../../components/Snackbar/SnackbarContext';
import { calculateMessageIntent } from './email-stage-editor/EmailStageEditor';

export function OutreachConfigEditor() {
  const [currentOutreachStageIndex, setCurrentOutreachStageIndex] = useState<number>(0);
  const project = useAppStore(state => state.project);
  const upsertOutreach = useAppStore(state => state.upsertOutreach);
  const outreach = useAppStore(state => state.outreach);
  const [outreachStages, setOutreachStages] = useState<IOutreachStage[]>(
    !outreach?.stages?.length ? [{}] : outreach.stages
  );
  const navigate = useNavigate();
  const { projectId } = useParams();
  const stageRef = React.createRef<StageImperativeHandler>();
  const [saving, setSaving] = useState(false);
  const currentOutreachStage = outreachStages?.[currentOutreachStageIndex || 0];
  const { showSuccessSnackbar } = useSnackbar();

  const handleBack = () => {
    navigate(`/project/${projectId}${OUTREACH_HOME_PATH}`);
  };

  const handleOutreachConfigSave = async () => {
    const newOutreachStages = populateCurrentOutreachStage();

    setSaving(true);
    try {
      await upsertOutreach({
        ...outreach,
        stages: newOutreachStages.map((stage, index) => ({
          ...stage,
          messageConfig: stage.messageConfig
            ? {
                ...stage.messageConfig,
                promptConfig: {
                  ...stage.messageConfig?.promptConfig,
                  messageIntent: calculateMessageIntent(index, newOutreachStages.length),
                },
              }
            : undefined,
        })),
      });
      showSuccessSnackbar('Outreach sequence saved successfully');
    } finally {
      setSaving(false);
    }
  };

  const populateCurrentOutreachStage = () => {
    const stageConfig = stageRef.current?.getStageConfig()!;
    const newStages = [
      ...(outreachStages?.slice(0, currentOutreachStageIndex) || []),
      stageConfig,
      ...(outreachStages?.slice(currentOutreachStageIndex! + 1) || []),
    ];
    setOutreachStages(newStages);
    return newStages;
  };

  const handleAppendStage = () => {
    const newStages = populateCurrentOutreachStage();
    setOutreachStages([...(newStages || []), {}]);
    setCurrentOutreachStageIndex(newStages.length);
  };

  const handleGoToStage = (index: number) => {
    populateCurrentOutreachStage();
    setCurrentOutreachStageIndex(index);
  };

  const handleStageRemove = (stageIndex: number) => {
    if (outreachStages.length === 1 && stageIndex === 0) {
      setOutreachStages([{}]);
      return;
    }

    const newStages = outreachStages.filter((_, index) => index !== stageIndex);
    if (stageIndex === outreachStages.length - 1) {
      setCurrentOutreachStageIndex(stageIndex - 1);
    }
    setOutreachStages(newStages);
  };

  return (
    <>
      <div className={styles.header}>
        <Button
          variant="plain"
          className={styles.backBtn}
          onClick={handleBack}
          startDecorator={<ArrowBackIosIcon />}
          size="sm"
          sx={{
            '&:hover': {
              backgroundColor: 'transparent !important',
            },
          }}
        >
          Outreach home
        </Button>
        <Typography level="title-md" className={styles.headerContent}>
          Setup Outreach Sequence for {project?.name}
        </Typography>
        <Button onClick={handleOutreachConfigSave} loading={saving} size="sm">
          Save
        </Button>
      </div>
      <div className={styles.stepperContainer}>
        <Stepper size="sm" sx={{ flexGrow: 1 }}>
          {outreachStages?.length &&
            outreachStages.map((stage, index) => (
              <Step
                className={styles.step}
                onClick={() => handleGoToStage(index)}
                indicator={
                  <StepIndicator
                    variant={currentOutreachStageIndex === index ? 'solid' : 'outlined'}
                    color={currentOutreachStageIndex === index ? 'primary' : 'neutral'}
                  >
                    {index + 1}
                  </StepIndicator>
                }
                orientation="vertical"
              >
                Stage {index + 1}
              </Step>
            ))}
          <Step
            onClick={handleAppendStage}
            className={styles.step}
            indicator={
              <StepIndicator variant="plain" color="neutral">
                <AddIcon />
              </StepIndicator>
            }
            orientation="vertical"
          >
            Add Stage
          </Step>
        </Stepper>
      </div>

      <OutreachStage
        currentOutreachStage={currentOutreachStage}
        // Add key to unmount/mount the component when the stage changes
        key={currentOutreachStage?.id || currentOutreachStageIndex}
        stageIndex={currentOutreachStageIndex}
        totalNumStages={outreachStages?.length}
        stageRef={stageRef}
        onRemoveStage={handleStageRemove}
      />
    </>
  );
}
