import { useEffect, useState } from 'react';
import { useAppStore } from '../../../../store/appStore';
import { OutreachThread } from 'api-types';
import { batchGetCandidateOutreachThreads } from '../../../../api/api';

export function useBatchGetOutreachThreads(candidateIds: string[]) {
  const candidateOutreachThreads = useAppStore(state => state.candidateOutreachThreads);
  const [loading, setLoading] = useState(false);

  const batchGetCandidateOutreachThread = useAppStore(
    state => state.batchGetCandidateOutreachThread
  );

  const fetchCandidateOutreachThreads = async (candidateIds: string[]) => {
    setLoading(true);
    try {
      await batchGetCandidateOutreachThread(candidateIds);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (candidateIds.length) {
      fetchCandidateOutreachThreads(candidateIds);
    }
  }, [candidateIds]);

  return {
    candidateOutreachThreads,
    loading,
    refresh: () => fetchCandidateOutreachThreads(candidateIds),
  };
}

export function useBatchGetOutreachThreadsByProjectId(projectId: string, candidateIds: string[]) {
  const [candidateOutreachThreads, setCandidateOutreachThreads] = useState<OutreachThread[]>();
  const [loading, setLoading] = useState(false);

  const fetchCandidateOutreachThreads = async (candidateIds: string[]) => {
    setLoading(true);
    try {
      const { candidateOutreachThreads } = await batchGetCandidateOutreachThreads({
        projectId,
        candidateIds,
      });
      setCandidateOutreachThreads(candidateOutreachThreads);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (candidateIds.length) {
      fetchCandidateOutreachThreads(candidateIds);
    }
  }, [projectId, candidateIds]);

  return {
    candidateOutreachThreads,
    loading,
    refresh: () => fetchCandidateOutreachThreads(candidateIds),
  };
}
