import { PipelineStageEnum } from 'api-types';

export const PIPELINE_STAGE_TO_USER_STRINGS: {
  [key in PipelineStageEnum]: string;
} = {
  [PipelineStageEnum.ArkaRecommended]: 'Arka Recommended',
  [PipelineStageEnum.InternalDraft]: 'Internal Draft',
  [PipelineStageEnum.OutreachInitialized]: 'Stage 1',
  [PipelineStageEnum.Rejected]: 'Rejected',
  [PipelineStageEnum.Shortlist]: 'Shortlisted',
  [PipelineStageEnum.Starred]: 'Starred',
};
