"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MessageLength = exports.Tone = exports.MessageIntent = void 0;
var MessageIntent;
(function (MessageIntent) {
    MessageIntent["Introduction"] = "Introduction";
    MessageIntent["FollowUp"] = "FollowUp";
    MessageIntent["LastFollowUp"] = "LastFollowUp";
})(MessageIntent || (exports.MessageIntent = MessageIntent = {}));
var Tone;
(function (Tone) {
    Tone["Professional"] = "Professional";
    Tone["Casual"] = "Casual";
    Tone["Enthusiastic"] = "Enthusiastic";
    Tone["Persuasive"] = "Persuasive";
})(Tone || (exports.Tone = Tone = {}));
var MessageLength;
(function (MessageLength) {
    MessageLength["VeryShort"] = "VeryShort";
    MessageLength["Short"] = "Short";
    MessageLength["Medium"] = "Medium";
    MessageLength["Long"] = "Long";
})(MessageLength || (exports.MessageLength = MessageLength = {}));
