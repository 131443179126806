import { resolve } from 'path';
import * as pdfjsLib from 'pdfjs-dist';
import { PDFDocumentProxy, TextItem } from 'pdfjs-dist/types/src/display/api';
import { FileContent } from 'api-types';

pdfjsLib.GlobalWorkerOptions.workerSrc =
  'https://cdnjs.cloudflare.com/ajax/libs/pdf.js/4.0.379/pdf.worker.min.mjs';

export function extractTextFromFiles(files: File[]): Promise<FileContent[]> {
  return new Promise<FileContent[]>((resolve, reject) => {
    const fileContents: FileContent[] = [];
    for (const file of files) {
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(file);
      fileReader.onload = async function () {
        const content = await extractTextFromFileReader(fileReader);
        fileContents.push({ content, fileName: file.name });
        if (fileContents.length === files.length) {
          resolve(fileContents);
        }
      };
      fileReader.onerror = () => {
        reject('Error reading file');
      };
    }
  });
}

async function readPage(pdf: PDFDocumentProxy, pageNumber: number) {
  const page = await pdf.getPage(pageNumber);
  const textContent = await page.getTextContent();
  return textContent.items
    .map(function (s) {
      return (s as TextItem).str;
    })
    .join(' ');
}

async function extractTextFromFileReader(fileReader: FileReader) {
  const typedArray = new Uint8Array(fileReader.result as ArrayBuffer);
  const loadingTask = pdfjsLib.getDocument(typedArray);
  const pdf = await loadingTask.promise;
  const maxPages = pdf.numPages;
  const promises = [];

  for (let pageNumber = 1; pageNumber <= maxPages; pageNumber++) {
    promises.push(readPage(pdf, pageNumber));
  }
  const text = await Promise.all(promises);
  return text.join('\r\n');
}
