import { Pagination, PaginationItem } from '@mui/material';
import styles from './candidate.module.css';
import { Candidate, CandidateItem } from 'api-types';
import { CandidateListItem } from './CandidateListItem';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
interface CandidateListProps {
  candidates: CandidateItem[];
  paginationConfig?: PaginationConfig;
  renderActions?: (candidate: Candidate) => React.ReactNode;
}

interface PaginationConfig {
  currentPage: number;
  handlePageChange: (pageNumber: number) => void;
}

export function CandidateList({ candidates, renderActions, paginationConfig }: CandidateListProps) {
  const { currentPage, handlePageChange } = paginationConfig || {};
  return (
    <>
      {candidates?.map((cand, index) => (
        <CandidateListItem candidate={cand} key={index} renderActions={renderActions} />
      ))}
      {paginationConfig && (
        <div className={styles.paginationContainer}>
          <Pagination
            variant="outlined"
            shape="rounded"
            count={2}
            page={(currentPage || 0) + 1}
            onChange={(e, val) => handlePageChange?.(val - 1)}
            renderItem={item => (
              <PaginationItem
                slots={{ previous: ArrowBackIcon, next: ArrowForwardIcon }}
                {...item}
              />
            )}
          />
        </div>
      )}
    </>
  );
}
