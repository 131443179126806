import { useEffect, useState } from 'react';
import { useAppStore } from '../../../../store/appStore';
import { Outreach, OutreachMessage } from 'api-types';
import { getAllOutreachMessages, getOutreach } from '../../../../api/api';

export function useLatestOutreachMessage(candidateId: string) {
  const getAllOutreachMessages = useAppStore(state => state.getAllOutreachMessages);
  const outreach = useAppStore(state => state.outreach);
  const [loading, setLoading] = useState(false);

  const [messages, setMessages] = useState<OutreachMessage[]>();

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const messages = await getAllOutreachMessages(candidateId!);
      setMessages(messages);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [candidateId]);

  const lastMessage = messages?.[messages.length - 1];
  const lastStage = outreach?.stages?.[outreach.stages.length - 1];

  return {
    message: lastMessage,
    subject: lastStage?.messageConfig?.subject,
    loading,
  };
}

export function useLatestOutreachMessageByProjectId(projectId: string, candidateId: string) {
  const [loading, setLoading] = useState(false);

  const [messages, setMessages] = useState<OutreachMessage[]>();
  const [outreach, setOutreach] = useState<Outreach>();

  const fetchMessages = async () => {
    setLoading(true);
    try {
      const { outreachMessages } = await getAllOutreachMessages({
        projectId,
        candidateId,
      });
      const { outreach } = await getOutreach({ projectId });
      setMessages(outreachMessages);
      setOutreach(outreach);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, [projectId, candidateId]);

  const lastMessage = messages?.[messages.length - 1];
  const lastStage = outreach?.stages?.[outreach.stages.length - 1];

  return {
    message: lastMessage,
    subject: lastStage?.messageConfig?.subject,
    loading,
  };
}
