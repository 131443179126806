import { useEffect, useState } from 'react';
import { useAppStore } from '../../../store/appStore';
import styles from './project.module.css';
import _ from 'lodash';
import { Drawer, Stack } from '@mui/material';
import { ProjectAssistant } from '../project-assistant/ProjectAssistant';
import { CriteriaForm } from '../criteria-form/CriteriaForm';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import { Typography, Button } from '@mui/joy';
import { SEARCH_CANDIDATES_PAGE_SIZE } from '../../candidate/slice/candidateSlice';
import { useScrollToTop } from '../../../common/useScrollToTop';
import { Candidate, PipelineStageEnum } from 'api-types';
import { SpinnerLoader } from '../../../components/Loader/SpinnerLoader';
import { CandidateList } from '../../candidate/CandidateList';
import { useCandidatesPipelineFetcher } from '../../candidate/hooks/useCandidatesPipelineFetcher';
import { ProjectForm } from '../project-form/ProjectForm';

export function ProjectSourceScreen() {
  const project = useAppStore(state => state.project);
  const batchUpdateCandidateStages = useAppStore(state => state.batchUpdateCandidateStages);
  const [projectDrawerView, setProjectDrawerView] = useState<'filter' | 'edit' | 'chat'>();
  const [shortListing, setShortlisting] = useState(false);
  const { candidatePipeline, loading, refresh } = useCandidatesPipelineFetcher([
    PipelineStageEnum.ArkaRecommended,
  ]);

  const handleShortlist = async (id: string) => {
    setShortlisting(true);
    try {
      await batchUpdateCandidateStages([
        {
          candidateId: id,
          stage: PipelineStageEnum.Shortlist,
        },
      ]);
      await refresh();
    } finally {
      setShortlisting(false);
    }
  };

  const handleReject = async (id: string) => {
    setShortlisting(true);
    try {
      await batchUpdateCandidateStages([
        {
          candidateId: id,
          stage: PipelineStageEnum.Rejected,
        },
      ]);
      await refresh();
    } finally {
      setShortlisting(false);
    }
  };

  const renderShortlistButton = (candidate: any) => {
    return (
      <Button
        size="sm"
        color={'neutral'}
        variant="outlined"
        onClick={() => handleShortlist(candidate.id)}
        disabled={shortListing}
      >
        Shortlist
      </Button>
    );
  };

  const renderRejectButton = (candidate: any) => {
    return (
      <Button
        size="sm"
        color={'neutral'}
        variant="outlined"
        onClick={() => handleReject(candidate.id)}
        disabled={shortListing}
      >
        Reject
      </Button>
    );
  };

  const renderActions = (candidate: Candidate) => {
    return (
      <div className={styles.actions}>
        {renderRejectButton(candidate)}
        {renderShortlistButton(candidate)}
      </div>
    );
  };

  const candidates = candidatePipeline?.pipelineStages?.[0]?.candidates;

  const getDrawerView = () => {
    if (projectDrawerView === 'filter') {
      return <CriteriaForm />;
    } else if (projectDrawerView === 'chat') {
      return <ProjectAssistant />;
    } else if (projectDrawerView === 'edit') {
      return <ProjectForm project={project} />;
    }
  };

  return (
    <div>
      <div className={styles.header}>
        <Typography level="title-md" color="neutral" fontWeight="lg">
          {project?.name}
        </Typography>
        <Stack direction={'row'} spacing={0}>
          <Button color={'neutral'} variant="plain" onClick={() => setProjectDrawerView('filter')}>
            Filters
          </Button>
          <Button color={'neutral'} variant="plain" onClick={() => setProjectDrawerView('edit')}>
            Edit
          </Button>
          {/* <Button
            color={'neutral'}
            variant="plain"
            onClick={() => setProjectDrawerView('chat')}
            startDecorator={<AutoAwesomeOutlinedIcon />}
          >
            Assistant
          </Button> */}
        </Stack>
      </div>
      <div className={styles.candidateSearchContainer}>
        {loading ? (
          <SpinnerLoader />
        ) : candidates?.length ? (
          <CandidateList candidates={candidates || []} renderActions={renderActions} />
        ) : (
          <Typography level="body-md" color="neutral">
            Candidate search is empty, please wait for more candidates to be added.
          </Typography>
        )}
      </div>
      <Drawer
        anchor={'right'}
        open={Boolean(projectDrawerView)}
        onClose={() => setProjectDrawerView(undefined)}
      >
        <div className={styles.drawerContainer}>{getDrawerView()}</div>
      </Drawer>
    </div>
  );
}
