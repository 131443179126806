import { Link } from '@mui/joy';
import linkedInLogo from '../../assets/linkedIn_logo.png';
import styles from './logo.module.css';

export function LinkediInLogo({ url }: { url: string }) {
  return (
    <Link href={url} target="_blank">
      <img src={linkedInLogo} alt="logo" className={styles.linkedInLogo} />
    </Link>
  );
}
