import React, { useEffect } from 'react';
import styles from './admin.module.css';
import { DRAWER_WIDTH } from '../../constants';
import { hasAdminAccess } from '../../api/api';
import { AdminNavigation } from './AdminNavigation';
import { Route, Routes } from 'react-router-dom';
import { NAV_ITEMS } from './constants';
import { Snackbar } from '../../components/Snackbar/SnackbarContext';

export function AdminApp() {
  useEffect(() => {
    hasAdminAccess().then(response => {
      if (!response?.success) {
        window.location.href = '/';
      }
    });
  }, []);

  return (
    <div className={styles.app}>
      <Snackbar>
        <AdminNavigation />
        <div className={styles.container} style={{ width: `calc(100% - ${DRAWER_WIDTH}px)` }}>
          <Routes>
            {NAV_ITEMS.map(({ path, Component }) => (
              <Route path={`${path}/*`} element={Component ? <Component /> : <></>} />
            ))}
          </Routes>
        </div>
      </Snackbar>
    </div>
  );
}
