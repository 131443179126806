import { CandidateItem } from 'api-types';
import { Typography } from '@mui/joy';
import styles from '../../candidate.module.css';
import { LinkediInLogo } from '../../../../components/Logo/LinkedinLogo';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import PhoneIcon from '@mui/icons-material/Phone';

export function CandidateHeaderDetails({ candidate }: { candidate: CandidateItem }) {
  return (
    <div className={styles.section}>
      <div className={styles.profileName}>
        <div>
          <Typography
            level="title-md"
            fontWeight={'lg'}
            gutterBottom
            sx={{ display: 'flex', alignItems: 'center' }}
          >
            {candidate.name}
            {candidate?.linkedinUrl && <LinkediInLogo url={candidate?.linkedinUrl} />}
          </Typography>

          <div className={styles.cardHeading}>
            <Typography level="body-sm" gutterBottom>
              {candidate.experiences?.[0]?.position}
            </Typography>
            <Typography level="body-sm" gutterBottom>
              {candidate.city}
            </Typography>
          </div>
        </div>
        <div>
          {candidate.email && (
            <Typography
              level="body-sm"
              gutterBottom
              startDecorator={<MailOutlineIcon fontSize="small" />}
            >
              {candidate.email}
            </Typography>
          )}
          {candidate.phoneNumber && (
            <Typography
              level="body-sm"
              gutterBottom
              startDecorator={<PhoneIcon fontSize="small" />}
            >
              {candidate.phoneNumber}
            </Typography>
          )}
        </div>
      </div>
    </div>
  );
}
