import { DropdownOption } from 'api-types';
import { AutoComplete } from '../../../../components/AutoComplete';
import { AutocompleteOption } from '@mui/joy';
import moment from 'moment';

interface DateIntervalProps {
  label: string;
  value: string | undefined;
  onChange: (value: string) => void;
  options: DropdownOption[];
  defaultOptions: string[];
}

const renderFilteredOption = (props: any, option: DropdownOption, defaultOptions: string[]) =>
  defaultOptions.includes(option.value as string) ? (
    <AutocompleteOption {...props}>{option.displayValue}</AutocompleteOption>
  ) : null;

export function DateInterval({
  label,
  value,
  onChange,
  defaultOptions,
  options,
}: DateIntervalProps) {
  return (
    <AutoComplete
      label={label}
      size="medium"
      value={value || ''}
      onChange={value => onChange(value as string)}
      options={options}
      renderOption={(props, option, state) => {
        if (state.inputValue) {
          if (
            defaultOptions.map(o => moment.duration(o).humanize(true)).includes(state.inputValue)
          ) {
            return renderFilteredOption(props, option, defaultOptions);
          } else if (options.map(o => o.displayValue).includes(state.inputValue)) {
            return option.displayValue === state.inputValue ||
              defaultOptions.includes(option.value as string) ? (
              <AutocompleteOption {...props}>{option.displayValue}</AutocompleteOption>
            ) : null;
          } else {
            return <AutocompleteOption {...props}>{option.displayValue}</AutocompleteOption>;
          }
        } else {
          return renderFilteredOption(props, option, defaultOptions);
        }
      }}
    />
  );
}
