import { PipelineStageEnum } from 'api-types';
import { Chip } from '../../../../components/Chip';
import { PIPELINE_STAGE_TO_USER_STRINGS } from '../constants';
import { capitalCase } from 'change-case';

export function StageChip({ stage }: { stage: PipelineStageEnum | string | undefined }) {
  return (
    <Chip
      color={stage === PipelineStageEnum.OutreachInitialized ? 'primary' : 'neutral'}
      backgroundColor={stage === PipelineStageEnum.Shortlist ? 'gray' : undefined}
    >
      {PIPELINE_STAGE_TO_USER_STRINGS[stage as PipelineStageEnum] || stage || ''}
    </Chip>
  );
}

export enum StatusEnum {
  NotInitialized = 'NotInitialized',
  InProcess = 'InProcess',
  Finished = 'Finished',
}

export function StatusChip({ status }: { status: StatusEnum }) {
  if (status === StatusEnum.NotInitialized) {
    return <Chip backgroundColor="gray">{capitalCase(StatusEnum.NotInitialized)}</Chip>;
  }
  if (status === StatusEnum.InProcess) {
    return <Chip color="primary">{capitalCase(StatusEnum.InProcess)}</Chip>;
  }
  return (
    <Chip color="success" backgroundColor="green">
      {StatusEnum.Finished}
    </Chip>
  );
}

export enum RepliedEnum {
  NotReplied = 'NotReplied',
  Interested = 'Interested',
  No = 'No',
}

export function RepliedChip({ replied }: { replied: RepliedEnum }) {
  if (replied === RepliedEnum.NotReplied) {
    return <>-</>;
  }
  return replied === RepliedEnum.Interested ? (
    <Chip color="success" backgroundColor="green">
      {RepliedEnum.Interested}
    </Chip>
  ) : (
    <Chip color="warning">No</Chip>
  );
}
