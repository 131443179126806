import { CandidateRecommendationRoutes } from './candidate-recommendation/CandidateRecomendationRoutes';
import { PhoneConversation } from './phone-conversation/PhoneConversation';

export const ADMIN_HOME_PATH = '/admin';
export const CANDIDATE_RECOMMENDATIONS_PATH_SECTION = 'candidate_recommendations';
export const NAV_ITEMS = [
  {
    title: 'Candidate Recommendations',
    path: `${CANDIDATE_RECOMMENDATIONS_PATH_SECTION}`,
    Component: CandidateRecommendationRoutes,
  },
  {
    title: 'Impersonate User',
    path: 'impersonate_user',
  },
  {
    title: 'Phone Conversations',
    path: 'phone_conversations',
    Component: PhoneConversation,
  },
];
