import { styled } from '@mui/joy';
import { Popper } from '@mui/base/Popper';
import { ClickAwayListener } from '@mui/base/ClickAwayListener';
import { PropsWithChildren } from 'react';

export const Popup = styled(Popper)({
  zIndex: 1000,
  width: '300px',
});

export function Popover({
  open,
  buttonRef,
  onHide,
  children,
}: PropsWithChildren<{
  open: boolean;
  buttonRef: React.RefObject<HTMLButtonElement>;
  onHide: () => void;
}>) {
  return (
    <>
      <Popup
        role={undefined}
        id="composition-menu"
        open={open}
        anchorEl={buttonRef.current}
        disablePortal
        modifiers={[
          {
            name: 'offset',
            options: {
              offset: [0, 4],
            },
          },
        ]}
        placement="bottom-start"
      >
        <ClickAwayListener
          onClickAway={event => {
            if (event.target !== buttonRef.current) {
              onHide();
            }
          }}
        >
          {children as any}
        </ClickAwayListener>
      </Popup>
    </>
  );
}
