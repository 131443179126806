import styles from '../create-project/create-project.module.css';
import { useState } from 'react';
import { Project } from 'api-types';
import SaveIcon from '@mui/icons-material/Save';
import { FormControl, FormLabel, FormHelperText, Typography, Button } from '@mui/joy';
import { Textarea } from '../../../components/TextArea';
import { Input } from '../../../components/Input';
import { useAppStore } from '../../../store/appStore';
import { useSnackbar } from '../../../components/Snackbar/SnackbarContext';

interface CreateProjectForm {
  project?: Project;
  onSubmitSuccess?: (project: Project | undefined) => void;
}

export function ProjectForm(props: CreateProjectForm) {
  const { project, onSubmitSuccess } = props;
  const [isSubmitting, setIsSubmitting] = useState(false);
  const upsertProject = useAppStore(state => state.upsertProject);

  const [projectName, setProjectName] = useState(project?.name || '');
  const [jobDescription, setJobDescription] = useState(project?.jobDescription || '');
  const { showSuccessSnackbar } = useSnackbar();

  const handleSubmit = async () => {
    try {
      setIsSubmitting(true);
      const createdProject = await upsertProject(
        {
          name: projectName,
          jobDescription,
        },
        project?.id
      );
      showSuccessSnackbar(
        project?.id ? 'Project updated successfully' : 'Project created successfully'
      );
      onSubmitSuccess?.(createdProject);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isButtonDisabled = !(jobDescription && projectName);

  return (
    <>
      <div className={styles.searchFormContainer}>
        <FormControl>
          <FormLabel>Project Name</FormLabel>
          <Input
            variant="outlined"
            value={projectName}
            onChange={e => setProjectName(e.target.value)}
          />
        </FormControl>
        <div>
          <div className={styles.semiHeading}>
            <Typography level="h4" color={'neutral'}>
              Who are you looking for?
            </Typography>
          </div>
          <FormControl>
            <FormLabel htmlFor="jd">Job Description or search query</FormLabel>
            <Textarea
              id="jd"
              minRows={4}
              value={jobDescription}
              onChange={(e: any) => setJobDescription(e.target.value)}
            />
            <FormHelperText id="jd">
              Paste a job description or a search query e.g. looking for a software engineer in the
              Bay area
            </FormHelperText>
          </FormControl>
        </div>
      </div>
      <div className={styles.searchActionBar}>
        <Button
          variant="outlined"
          color="neutral"
          onClick={handleSubmit}
          disabled={isButtonDisabled}
          loading={isSubmitting}
          startDecorator={<SaveIcon />}
        >
          {project ? 'Update' : 'Create'}
        </Button>
      </div>
    </>
  );
}
