import { TabList as MuiTabList, tabClasses } from '@mui/joy';
import { PropsWithChildren } from 'react';

export const TabList = ({
  className,
  children,
  size,
}: PropsWithChildren<{ className: string; size?: 'sm' | 'md' | 'lg' }>) => {
  return (
    <MuiTabList
      className={className}
      color="neutral"
      size={size}
      sx={{
        pt: 1,
        [`&& .${tabClasses.root}`]: {
          flex: 'initial',
          bgcolor: 'transparent',
          fontWeight: 'md',
          '&:hover': {
            bgcolor: 'transparent',
          },
          color: 'rgb(102, 102, 102)',
          [`&.${tabClasses.selected}`]: {
            color: 'rgb(23, 26, 28)',
          },
        },
      }}
    >
      {children}
    </MuiTabList>
  );
};
