import { useRef, useState } from 'react';

export function useStreaming() {
  // Needs to be a ref to access latest value in callback fn.
  const streamingMessage = useRef('');
  // Do not deleted: streamedCount is kept to cause rerender since streaming message is a ref.
  const [streamedCount, setStreamedCount] = useState(0);
  const [streamingError, setStreamingError] = useState(false);
  const [loading, setLoading] = useState(false);

  function onError() {
    setTimeout(() => {
      setStreamingError(false);
    }, 5000);
    setStreamingError(true);
    setLoading(false);
  }

  const onDone = () => {
    setStreamingError(false);
    setLoading(false);
  };

  const onInitializeStreaming = () => {
    setLoading(true);
    streamingMessage.current = '';
    setStreamedCount(0);
  };

  const onChunkReceive = (content: string) => {
    streamingMessage.current = streamingMessage.current + content;
    setStreamedCount(streamedCount => streamedCount + 1);
  };

  return {
    message: streamingMessage,
    streamingError,
    loading,
    onInitializeStreaming,
    onError,
    onChunkReceive,
    onDone,
  };
}
