import { useAppStore } from '../../../store/appStore';
import styles from './project-assitant.module.css';
import React, { useEffect, useRef, useState } from 'react';
import { SendProjectMessageResponse, Role } from 'api-types';
import classNames from 'classnames';
import SendIcon from '@mui/icons-material/Send';
import { Alert, Input, Typography } from '@mui/joy';
import { AssitantLoader } from '../../../components/Loader/AssitantLoader';
import { useStreaming } from '../../../common/useStreaming';
import { useScrollToBottom } from '../../../common/useScrollToBottom';

export function ProjectAssistant() {
  const project = useAppStore(state => state.project);
  const [inputMessage, setInputMessage] = useState('');
  const sendProjectMessage = useAppStore(state => state.sendProjectMessage);
  const appendNewMessage = useAppStore(state => state.appendNewMessage);
  const { containerRef: messagesContainerRef, scrollToBottom } = useScrollToBottom();

  const {
    message,
    streamingError,
    loading,
    onInitializeStreaming,
    onChunkReceive,
    onDone,
    onError,
  } = useStreaming();
  const streamingMessage = message.current;

  const onMessageChunkReceive = ({ message }: SendProjectMessageResponse) => {
    onChunkReceive(message?.content || '');
    scrollToBottom();
  };

  const onMessageStreamDone = () => {
    appendNewMessage({
      content: message.current!,
      role: Role.Assistant,
    });
    onDone();
  };

  function onMessageStreamError() {
    onError();
  }

  const handleMessageSend = (e: React.FormEvent) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    const message = inputMessage.trim();
    appendNewMessage({
      content: message,
      role: Role.User,
      createdAt: new Date().toISOString(),
    });
    setInputMessage('');
    try {
      onInitializeStreaming();
      sendProjectMessage(message, {
        onMessage: onMessageChunkReceive,
        onDone: onMessageStreamDone,
        onError: onMessageStreamError,
      });
    } catch (e) {
      onError();
    }
  };

  useEffect(() => {
    scrollToBottom();
  }, []);

  const messages = project?.messages;

  useEffect(() => {
    if (messagesContainerRef.current) {
      scrollToBottom('smooth');
    }
  }, [messages, messagesContainerRef]);

  if (!project) {
    return null;
  }

  return (
    <div className={styles.assitantContainer}>
      <div className={styles.assitantHeader}>
        <Typography level="title-sm">Refine your search with chat queries like</Typography>
        <Typography level="body-md" color={'neutral'}>
          Show me candidates working in series a startups? Or Show me candidates PHDs in machine
          learning?
        </Typography>
      </div>
      <div className={styles.messagesContainer} ref={messagesContainerRef}>
        {messages
          ?.sort((a, b) => (new Date(a.createdAt!) as any) - (new Date(b.createdAt!) as any))
          .map((message, index) => (
            <div
              className={classNames(styles.message, {
                [styles.assistantMessage]: message.role === Role.Assistant,
                [styles.userMessage]: message.role === Role.User,
              })}
              key={index}
            >
              {message.role === Role.Assistant ? (
                <Typography level="title-sm" fontWeight="lg">
                  Assistant
                </Typography>
              ) : (
                <Typography level="title-sm" fontWeight="lg">
                  You
                </Typography>
              )}
              <Typography level="body-md" component={'div'}>
                {message.content}
              </Typography>
            </div>
          ))}
        {streamingMessage && loading && (
          <div className={classNames(styles.message, styles.assistantMessage)}>
            <Typography level="title-sm" fontWeight="lg">
              Assistant
            </Typography>
            <Typography level="body-md" component={'div'}>
              {streamingMessage}
            </Typography>
          </div>
        )}
        {streamingError && (
          <Alert color="danger" variant="soft">
            There was an error in getting the response, please try again.
          </Alert>
        )}
      </div>
      <div className={styles.messageForm}>
        {loading && !streamingMessage && (
          <div className={styles.loader}>
            <AssitantLoader />
          </div>
        )}
        <form onSubmit={handleMessageSend}>
          <Input
            size="lg"
            sx={{
              'border-image-source': 'linear-gradient(to right, #4755e6, #d34afc)',
              '&::before': {
                display: 'none',
              },
              'border-width': '2px',
              '&:focus-within': {
                outlineOffset: '2px',
                'border-image-source': 'linear-gradient(to right, #0016ff, #c400ff)',
              },
            }}
            className={styles.messageInput}
            placeholder="Search for candidates. Example: Show me candidates working in series a startups?"
            endDecorator={
              <SendIcon
                onClick={handleMessageSend}
                sx={{
                  '&:hover': {
                    cursor: 'pointer',
                  },
                }}
              />
            }
            onChange={e => setInputMessage(e.target.value)}
            value={inputMessage}
          />
        </form>
      </div>
    </div>
  );
}
