import { useEffect, useState } from 'react';
import { getAllProjectsForUser, getAllUsers } from '../../../api/api';
import { Project, User } from 'api-types';

export function useUserAndProjectSelector() {
  const [allUsers, setAllUsers] = useState<User[]>();
  const [selectedUserId, setSelectedUserId] = useState<string>();
  const [projects, setProjects] = useState<Project[]>();
  const [selectedProjectId, setSelectedProjectId] = useState<string>();

  useEffect(() => {
    async function fetchAllUsers() {
      const { users } = await getAllUsers();
      setAllUsers(users);
    }
    fetchAllUsers();
  }, []);

  useEffect(() => {
    if (!selectedUserId) {
      return;
    }

    async function fetchAllProjectsForSelectedUser() {
      const { projects } = await getAllProjectsForUser({
        userId: selectedUserId!,
      });
      setProjects(projects || []);
    }

    fetchAllProjectsForSelectedUser();
  }, [selectedUserId]);

  useEffect(() => {
    if (!selectedUserId) {
      setSelectedProjectId(undefined);
    }
  }, [selectedUserId]);
  return {
    allUsers,
    projects,
    selectedUserId,
    selectedProjectId,
    setSelectedUserId,
    setSelectedProjectId,
  };
}
