import { Button, MenuList } from '@mui/joy';
import { useRef, useState } from 'react';

import { PipelineStageEnum } from 'api-types';
import { ColumnFilter, Table } from '@tanstack/react-table';
import { CandidatePipelineTableRow } from '../table/CandidatePipelineTable';
import { Chip } from '../../../../../components/Chip';
import { PIPELINE_STAGE_TO_USER_STRINGS } from '../../constants';
import { Popover } from '../../../../../components/Popover';
import { Filter, FilterConfig } from './FilterComponents';

export function PipelineFilterButton({
  filter,
  tableInstance,
  filterConfigs,
}: {
  filter: ColumnFilter;
  tableInstance: Table<CandidatePipelineTableRow>;
  filterConfigs: FilterConfig[];
}) {
  const buttonRef = useRef(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const handleOpenToggle = (open: boolean) => {
    setFilterOpen(open);
  };

  const getHeaderLabelByColId = (colId: string) => {
    const header = tableInstance.getHeaderGroups()[0].headers.find(header => header.id === colId);
    return header?.column.columnDef.header;
  };

  const getFilterValue = (filterId: string, value: unknown) => {
    return (
      filterConfigs.find(config => config.id === filter.id)?.getFilterValue?.(value as string) ||
      value
    );
  };

  return (
    <>
      <Button
        size="sm"
        ref={buttonRef}
        aria-haspopup="true"
        aria-expanded={filterOpen ? 'true' : undefined}
        variant="plain"
        color="neutral"
        onClick={() => {
          handleOpenToggle(!filterOpen);
        }}
        sx={{ backgroundColor: 'transparent !important' }}
      >
        <Chip color="primary">{`${getHeaderLabelByColId(filter.id)}: ${getFilterValue(filter.id, filter.value)}`}</Chip>
      </Button>
      <Popover open={filterOpen} onHide={() => handleOpenToggle(false)} buttonRef={buttonRef}>
        <MenuList
          size="sm"
          sx={{
            height: '200px',
            backgroundColor: 'rgb(255, 255, 255)',
            padding: '10px 0',
          }}
        >
          <Filter
            tableInstance={tableInstance}
            columnId={filter.id}
            value={filter.value as string}
            filterConfig={filterConfigs.find(config => config.id === filter.id)}
          />
        </MenuList>
      </Popover>
    </>
  );
}
