import { Button, MenuItem, MenuList, Typography } from '@mui/joy';
import { useRef, useState } from 'react';
import { ColumnFiltersState, Table } from '@tanstack/react-table';
import { CandidatePipelineTableRow } from '../table/CandidatePipelineTable';
import AddIcon from '@mui/icons-material/AddOutlined';
import { Popover } from '../../../../../components/Popover';
import { Filter, FilterConfig } from './FilterComponents';

interface PipelineFilterProps {
  tableInstance: Table<CandidatePipelineTableRow>;
  columnsFiltersState: ColumnFiltersState;
  filterConfigs: FilterConfig[];
}

export const getUnsetFilters = (
  filterConfigs: FilterConfig[],
  columnsFiltersState: ColumnFiltersState
) =>
  filterConfigs.filter(
    filter => !columnsFiltersState.some(filterState => filterState.id === filter.id)
  );

export function PipelineCreateFilterButton({
  tableInstance,
  columnsFiltersState,
  filterConfigs,
}: PipelineFilterProps) {
  const [open, setOpen] = useState(false);
  const buttonRef = useRef(null);
  const [selectedFilter, setSelectedFilter] = useState<string | undefined>();

  const handleFieldSelect = (field: string) => {
    setSelectedFilter(field);
  };

  const handleOpenToggle = (open: boolean) => {
    if (open) {
      setOpen(true);
    } else {
      setSelectedFilter(undefined);
      setOpen(false);
    }
  };

  return (
    <div>
      <Button
        size="sm"
        ref={buttonRef}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        variant="plain"
        color="neutral"
        onClick={() => {
          handleOpenToggle(!open);
        }}
        startDecorator={<AddIcon />}
      >
        Add Filter
      </Button>
      <Popover open={open} onHide={() => handleOpenToggle(false)} buttonRef={buttonRef}>
        <MenuList
          variant="outlined"
          sx={{ height: '200px', backgroundColor: 'rgb(255, 255, 255)', padding: '10px 0' }}
        >
          {selectedFilter ? (
            <Filter
              tableInstance={tableInstance}
              columnId={selectedFilter}
              onChange={() => handleOpenToggle(false)}
              filterConfig={filterConfigs.find(config => config.id === selectedFilter)}
            />
          ) : (
            getUnsetFilters(filterConfigs, columnsFiltersState).map(
              (filterConfig: FilterConfig) => (
                <MenuItem onClick={() => handleFieldSelect(filterConfig.id)}>
                  <Typography level="title-sm" fontWeight={'small'}>
                    {filterConfig.label}
                  </Typography>
                </MenuItem>
              )
            )
          )}
        </MenuList>
      </Popover>
    </div>
  );
}
