import {
  List,
  ListItem,
  RadioGroup,
  Radio,
  ListItemDecorator,
  ListItemButton,
  ListItemContent,
} from '@mui/joy';
import { useRef, useState } from 'react';

import { RepliedChip, RepliedEnum, StageChip, StatusChip, StatusEnum } from '../PipelineChips';
import { PipelineStageEnum } from 'api-types';
import { Column, Table } from '@tanstack/react-table';
import { CandidatePipelineTableRow } from '../table/CandidatePipelineTable';
import styles from '.././pipeline-table.module.css';
import { getCandidateStage } from '../table/utils';

export interface FilterConfig {
  id: string;
  label: string;
  FilterComponent: React.FC<{
    onChange: (value: string) => void;
    tableInstance?: Table<CandidatePipelineTableRow>;
    value?: string;
  }>;
  getFilterValue?: (value: string) => string | number | boolean;
}

export function Filter({
  tableInstance,
  columnId,
  onChange,
  value,
  filterConfig,
}: {
  columnId: string;
  tableInstance: Table<CandidatePipelineTableRow>;
  onChange?: (val: string) => void;
  value?: string;
  filterConfig?: FilterConfig;
}) {
  const handleOnChange = (
    column: Column<CandidatePipelineTableRow, unknown> | undefined,
    val: string
  ) => {
    column?.setFilterValue(val);
    onChange?.(val);
  };

  if (!filterConfig) {
    return null;
  }
  const header = tableInstance.getHeaderGroups()[0].headers.find(header => header.id === columnId);
  const Component = filterConfig.FilterComponent;
  return (
    <Component
      tableInstance={tableInstance}
      value={value}
      onChange={(val: string) => handleOnChange(header?.column, val)}
    />
  );
}

interface FilterProps {
  onChange: (value: string) => void;
  tableInstance?: Table<CandidatePipelineTableRow>;
  value?: string;
}

export function StageFilter({ onChange, value, tableInstance }: FilterProps) {
  const getStageItem = (stage: string) => {
    return <StageChip stage={stage as PipelineStageEnum} />;
  };
  const availableValues = new Set<string>();
  for (const datum of tableInstance?.options.data || []) {
    const stage = getCandidateStage(datum.stage, datum.candidateOutreachThread);
    if (stage) {
      availableValues.add(stage);
    }
  }

  return (
    <RadioFilter
      items={[...availableValues]}
      onChange={onChange}
      value={value}
      renderItem={getStageItem}
    />
  );
}

export function StatusFilter({ onChange, value }: FilterProps) {
  const getStatusItem = (status: string) => <StatusChip status={status as StatusEnum} />;
  return (
    <RadioFilter
      items={Object.values(StatusEnum)}
      onChange={onChange}
      value={value}
      renderItem={getStatusItem}
    />
  );
}

export function RepliedFilter({ onChange, value }: FilterProps) {
  const getRepliedItem = (item: string) => <RepliedChip replied={item as RepliedEnum} />;
  return (
    <RadioFilter
      items={[RepliedEnum.Interested, RepliedEnum.No]}
      onChange={onChange}
      value={value}
      renderItem={getRepliedItem}
    />
  );
}

interface RadioGroupProps {
  onChange: (value: string) => void;
  value?: string;
  items: string[];
  renderItem: (item: string) => React.ReactNode;
}

function RadioFilter({ onChange, value, items, renderItem }: RadioGroupProps) {
  return (
    <div className={styles.radioFilterContainer}>
      <RadioGroup
        aria-label="Radio Filter"
        name="radio-filter"
        value={value}
        onChange={e => onChange(e.target.value)}
      >
        <List>
          {items.map((item, index) => (
            <ListItem variant="plain" key={item}>
              <ListItemButton variant="plain">
                <ListItemDecorator>
                  <Radio overlay value={item} />
                </ListItemDecorator>
                <ListItemContent>{renderItem(item)}</ListItemContent>
              </ListItemButton>
            </ListItem>
          ))}
        </List>
      </RadioGroup>
      {value && (
        <ListItem className={styles.listItem}>
          <ListItemButton variant="plain" onClick={() => onChange('')}>
            Clear Selection
          </ListItemButton>
        </ListItem>
      )}
    </div>
  );
}
